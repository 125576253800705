import {useSelector} from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {Dayjs} from 'dayjs';
import {DateRange} from '@mui/x-date-pickers-pro';
import {isGTSelector} from '../../store/Slices/companySlice';
import {
  ISponsorDetailsProp,
  ISponsorshipRequestProp,
} from '../../dto/Sponsor.dto';
import {IMultipleFileUploadProp} from './MultipleFileUploadCmpt';
import MusicPreviewBtn from '../../components/music/MusicPreviewBtn';

type Props = {
  sponsorDetails?: ISponsorDetailsProp;
  dateRange?: DateRange<Dayjs>;
  isContactSponsor?: number;
  adSpot?: number;
  sponsorshipRequest: ISponsorshipRequestProp;
  onStepClick?: (index: number) => void;
  requestType?: string;
  from?: string;
  multipleFileUpload?: IMultipleFileUploadProp[];
};
const SponsorshipRequestReview = ({
  sponsorDetails,
  dateRange,
  adSpot,
  sponsorshipRequest,
  requestType,
  from,
  multipleFileUpload,
}: Props) => {
  const isGT = useSelector(isGTSelector);
  return (
    <>
      <Typography variant='h5' className='text-white font-bold mb-8'>
        Review
      </Typography>
      {from && from === 'by_sponsor' ? (
        <>
          <Typography variant='h6' className='mb-7 text-white-50'>
            {requestType && requestType === 'campaign-created'
              ? 'I am providing all of the information needed to produce the campaign for my sponsor'
              : 'I am providing all of the information needed to produce the commercial for my sponsor'}
          </Typography>

          {multipleFileUpload && multipleFileUpload.length > 0 ? (
            <SponsorMultipleFileReview
              multipleFileUpload={multipleFileUpload}
            />
          ) : (
            <BySponsorReview
              file_name={sponsorshipRequest.files?.name}
              message={sponsorshipRequest.message}
            />
          )}
        </>
      ) : (
        <>
          {sponsorDetails && (
            <>
              <TableContainer>
                <Table
                  sx={{
                    '.MuiTableCell-root, .MuiTableRow-root': {
                      border: 'none !important',
                      padding: '6px 0',
                    },
                  }}>
                  <TableBody>
                    <TableRow>
                      <TableCell className='text-white-50'>
                        <Typography variant='subtitle1'>
                          Sponsor/Company Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography aria-label='Sponsor/Company Name'>
                          {sponsorDetails.sponsor_name}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className='text-white-50'>
                        <Typography>Main Contact Name</Typography>
                      </TableCell>
                      <TableCell className='text-white'>
                        <Typography aria-label='Main Contact Name'>
                          {sponsorDetails.main_contact}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className='text-white-50'>
                        <Typography>Main Contact Email Address</Typography>
                      </TableCell>
                      <TableCell className='text-white'>
                        <Typography aria-label='Main Contact Email Address'>
                          {sponsorDetails.email_address}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className='text-white-50'>
                        <Typography>Main Contact Phone Number</Typography>
                      </TableCell>
                      <TableCell className='text-white'>
                        <Typography aria-label='Main Contact Phone Number'>
                          {sponsorDetails.phone_number}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className='text-white-50'>
                        <Typography>Sponsor Date</Typography>
                      </TableCell>
                      <TableCell className='text-white'>
                        {dateRange ? (
                          <Typography aria-label='Sponsor Date'>
                            Start Date: {dateRange[0]?.format('MM/DD/YYYY')} End
                            Date: {dateRange[1]?.format('MM/DD/YYYY')}
                          </Typography>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                    </TableRow>
                    {isGT && (
                      <TableRow>
                        <TableCell className='text-white-50'>
                          <Typography>Runs per event </Typography>
                        </TableCell>
                        <TableCell className='text-white'>{adSpot}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className='mt-10'>
                {multipleFileUpload && multipleFileUpload.length > 0 ? (
                  <SponsorMultipleFileReview
                    multipleFileUpload={multipleFileUpload}
                  />
                ) : (
                  <BySponsorReview
                    file_name={
                      sponsorshipRequest.files?.name ||
                      sponsorshipRequest.track?.track_name
                    }
                    message={sponsorshipRequest.message}
                  />
                )}
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

const SponsorMultipleFileReview = ({
  multipleFileUpload,
}: {
  multipleFileUpload: IMultipleFileUploadProp[];
}) => {
  return (
    <Box>
      <Typography className='text-white-50 mb-3'> Sponsor File </Typography>
      {multipleFileUpload.map(val => (
        <Typography className='text-white-50 mb-3' aria-label='Sponsor File'>
          {val.url ? (
            <MusicPreviewBtn
              id={val.url}
              url={val.url}
              track_name={val.fileName}
              track_artist={val.fileName}
            />
          ) : null}{' '}
          {val.fileName}.mp3
        </Typography>
      ))}
    </Box>
  );
};

const BySponsorReview = ({
  file_name,
  message,
}: {
  file_name?: string;
  message?: string;
}) => {
  return (
    <Box>
      {file_name ? (
        <>
          <Typography className='text-white-50 mb-3'> Sponsor File </Typography>
          <Typography className='text-white-50' aria-label='Sponsor File'>
            {file_name}
          </Typography>
        </>
      ) : (
        <>
          <Typography className='text-white-50 mb-3'>
            Sponsor Message
          </Typography>
          <Typography className='text-white' aria-label='Sponsor Message'>
            <pre>{message}</pre>
          </Typography>
        </>
      )}
    </Box>
  );
};
export default SponsorshipRequestReview;
