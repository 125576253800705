import {Box, Button} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useUserCanAny} from '../../hooks/useUserCan';
import {
  isNRSelector,
  isSZSelector,
  isTrampolineSelector,
  productLevelSelector,
} from '../../store/Slices/companySlice';

const MessagesTabNavigate = () => {
  const location = useLocation();
  const currentPath = location.pathname.split('/');
  const isTrampoline = useSelector(isTrampolineSelector);
  const isNR = useSelector(isNRSelector);
  const isSZ = useSelector(isSZSelector);
  const productLevel = useSelector(productLevelSelector);

  const current_message_type: string = currentPath[2] ? currentPath[2] : '';
  const eventEnable = useUserCanAny(
    'is-admin,partner,event-message:list,event-message:create,event-message:view,event-message:update,event-message:update-status,event-message:delete',
  );
  const ondemandEnable = useUserCanAny(
    'is-admin,partner,on-demand-message:list,on-demand-message:create,on-demand-message:update-status,on-demand-message:view,on-demand-message:delete,update-on-demand-message',
  );
  const baseEnable = useUserCanAny(
    'is-admin,partner,base-message:list,base-message:create,base-message:view,base-message:update,base-message:delete,base-message:convert-base-message',
  );
  const timedEnable = useUserCanAny(
    'is-admin,partner,timed-message:list,timed-message:create,timed-message:view,timed-message:delete',
  );
  const wristbandEnable = useUserCanAny(
    'is-admin,partner,wristband-schedule:list,wristband-schedule:create,wristband-schedule:update,wristband-schedule:schedule-for-date,wristband-schedule:delete-schedule-for-date',
  );
  const stingerEnable = useUserCanAny(
    'is-admin,partner,stinger-message:list,stinger-message:create,stinger-message:update-status,stinger-message:delete',
  );
  return (
    <Box className='flex gap-3'>
      {((eventEnable && !isSZ) ||
        (isSZ && productLevel !== 'Level 1' && eventEnable)) && (
        <Button
          color='primary'
          variant={
            current_message_type === 'event-message' ? 'contained' : 'outlined'
          }
          component={Link}
          to='/messages/event-message'>
          Event Specific
        </Button>
      )}

      {ondemandEnable && !isSZ && (
        <Button
          color='primary'
          variant={
            current_message_type === 'ondemand-message'
              ? 'contained'
              : 'outlined'
          }
          component={Link}
          to='/messages/ondemand-message'>
          On-demand
        </Button>
      )}

      {baseEnable && !isSZ && (
        <Button
          color='primary'
          variant={
            current_message_type === 'base-message' ? 'contained' : 'outlined'
          }
          component={Link}
          to='/messages/base-message'>
          Base
        </Button>
      )}

      {isNR && timedEnable && (
        <Button
          color='primary'
          variant={
            current_message_type === 'timed-message' ? 'contained' : 'outlined'
          }
          component={Link}
          to='/messages/timed-message'>
          Timed Message
        </Button>
      )}

      {isTrampoline && wristbandEnable && !isSZ ? (
        <Button
          color='primary'
          variant={
            current_message_type === 'wristband-message'
              ? 'contained'
              : 'outlined'
          }
          component={Link}
          to='/messages/wristband-message'>
          Wristband Message
        </Button>
      ) : null}
      {stingerEnable && (
        <Button
          color='primary'
          variant={
            current_message_type === 'stinger-message'
              ? 'contained'
              : 'outlined'
          }
          component={Link}
          to='/messages/stinger-message'>
          Stinger
        </Button>
      )}
    </Box>
  );
};

export default MessagesTabNavigate;
