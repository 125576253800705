import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {IApiDataResponse} from '../../dto/API.dto';
import {IMusicGenre} from '../../dto/MusicGenre.dto';
import axiosInstance from '../../axiosConfig';
import {useStationContext} from '../../components/models/stations/StationContext';

export interface IMusicGenreCust extends IMusicGenre {
  percentage: number;
  selected: boolean;
  status: string;
  count?: number;
  super_genre_id?: number;
  auto_generated_name?: string;
  visibility?: boolean;
  tracks_count?: number;
  is_song_set?: boolean;
}

export interface IMusicGenreGrouped extends IMusicGenreCust {
  sub_genres: IMusicGenreCust[];
}

export const listGenreGroup = (companyId: number) => {
  return `/v1/company/${companyId}/genre-group`;
};

const getGroupedGenres = (
  companyId: number,
  params?: IBlacklistingGenreParam,
) => {
  return axiosInstance
    .get(listGenreGroup(companyId), params ? {params} : undefined)
    .then((response: AxiosResponse<IApiDataResponse<IMusicGenreGrouped[]>>) => {
      return response.data?.data;
    });
};

export type IBlacklistingGenreParam = {
  station_id?: number[];
  school_id?: number[];
  event_id?: number[];
  no_empty_song_sets?: number;
  type: string;
};

export const useRqGroupedGenres = (
  companyId: number,
  enabled: boolean = true,
  params?: IBlacklistingGenreParam,
) => {
  return useQuery({
    queryKey: [listGenreGroup(companyId), params],
    queryFn: () => getGroupedGenres(companyId, params),
    staleTime: 600000,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled && Boolean(companyId),
  });
};

export const listGenreGroupWithoutCompany = () => {
  return `/v1/genre-groups`;
};
const getGroupedGenresWithoutCompany = (params?: IBlacklistingGenreParam) => {
  return axiosInstance
    .get(listGenreGroupWithoutCompany(), params ? {params} : undefined)
    .then((response: AxiosResponse<IApiDataResponse<IMusicGenreGrouped[]>>) => {
      return response.data?.data;
    });
};
export const useRqGroupedGenresWithoutCompany = (
  enabled: boolean = true,
  params?: IBlacklistingGenreParam,
) => {
  return useQuery({
    queryKey: [listGenreGroupWithoutCompany(), params],
    queryFn: () => getGroupedGenresWithoutCompany(params),
    staleTime: 600000,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: enabled,
  });
};
// check clip name is unique.
type IClipNameUniqueProp = {
  name_exist: boolean;
};

export const queryKeyClipNameUnique = (companyId: number) => {
  return `/v1/company/${companyId}/on-demand-playlist/create-clip/name-unique`;
};

export const useRqCheckClipNameUnique = (
  companyId: number,
  name: string,
  enabled: boolean = true,
) => {
  const {stations} = useStationContext();
  const stationIds = stations?.map(x => x.id) ?? [];

  return useQuery({
    queryKey: [queryKeyClipNameUnique(companyId), name, stationIds],
    queryFn: () => getClipNameUnique(companyId, name, stationIds),
    refetchOnWindowFocus: true,
    retry: false,
    enabled: enabled,
    initialData: {name_exist: false},
  });
};

const getClipNameUnique = (
  companyId: number,
  name: string,
  stationIds: number[],
) => {
  return axiosInstance
    .get(queryKeyClipNameUnique(companyId), {
      params: {name: name, stationIds: stationIds},
    })
    .then((response: AxiosResponse<IApiDataResponse<IClipNameUniqueProp>>) => {
      return response.data.data;
    });
};
