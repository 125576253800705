import dayjs from 'dayjs';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {ContentCopy, Edit, NotInterested, Restore} from '@mui/icons-material';
import {Box, IconButton, Stack, Typography} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import {
  IListQueryParms,
  queryKeyMessageRequestList,
  useRqGetMessageList,
} from '../../../react_query/messages/MessagesByCmpId';
import {DataGridCustPagination} from '../../../components/utlis/DataGrid/Pagination';
import {useStoreSelector} from '../../../store/configstore';
import {companyIdSelector} from '../../../store/Slices/companySlice';
import {useUserCanAny} from '../../../hooks/useUserCan';
import {GridCellExpand} from '../../../components/utlis/DataGrid/ExpandCellRender';
import CustomNoRowsOverlay from '../../../components/utlis/DataGrid/CustomNoRowsOverlay';
import IconAdd from '../../../asset/icons/icon_messaging.svg';
import IconAddEvent from '../../../asset/icons/icon_addsong.svg';
import IconViewButton from '../../../components/Button/IconViewButton';
import {UserCanAny} from '../../../components/UserCan';
import {TimedMsgDeActivate} from './TimedMsgRestoreDeActive';
import TimedMessageCreate from './TimedMessageCreate';
import DeleteRow from '../../../components/utlis/DataGrid/DeleteRow';

const TimedMsgDataGrid = ({
  search,
  query,
  setViewId,
  setQuery,
  toggleCreateDialog,
  status,
  setStatus,
}: {
  search: string;
  query: IListQueryParms;
  setViewId: (val: number) => void;
  toggleCreateDialog: () => void;
  setQuery: React.Dispatch<React.SetStateAction<IListQueryParms>>;
  status: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const company_id = useStoreSelector(companyIdSelector);
  const [pagination, setPagination] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const canEdit = useUserCanAny(
    'is-admin,partner,timed-message:view,timed-message:update,timed-message:delete,timed-message:create',
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Message Name',
      hideable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        <GridCellExpand
          value={params.row.name}
          width={params.colDef.computedWidth}
        />;
      },
    },
    {
      field: 'start_times',
      headerName: 'Message Time',
      hideable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const startTimes: string[] = JSON.parse(params.row.start_times);
        const formattedTimes = startTimes.map(time =>
          dayjs(time, 'HH:mm:ss').format('h:mm A'),
        );
        return formattedTimes.join(', ');
      },
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      hideable: false,
      width: 178,
      renderCell: (params: GridRenderCellParams) => {
        return dayjs(params.value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'created_by',
      headerName: 'Created By',
      hideable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <GridCellExpand
            value={params.row.created_by.name}
            width={params.colDef.computedWidth}
          />
        );
      },
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      hideable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        return dayjs(params.value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      hideable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => {
        return dayjs(params.value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      hideable: false,
      width: 150,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Action',
      width: 200,
      sortable: false,
      cellClassName: 'justify-center',
      renderCell: (params: GridRenderCellParams) => (
        <ActionDetails
          params={params}
          setViewId={setViewId}
          setStatus={setStatus}
        />
      ),
    },
  ].map(column => ({
    ...column,
    filterable: false,
  }));

  React.useEffect(() => {
    setQuery(prev => {
      return {
        ...prev,
        page: pagination.page + 1,
        per_page: pagination.pageSize,
        search: search,
        status,
      };
    });
  }, [company_id, pagination, search, setQuery, status]);
  const activeState = !!(Number(company_id) && Number(pagination.page + 1));

  const {data, isLoading} = useRqGetMessageList(
    Number(company_id),
    query,
    'timed-message',
    activeState,
  );

  const handleSortModelChange = (model: GridSortModel) => {
    if (model[0]) {
      setQuery(prev => ({...prev, ...model[0]}));
    }
  };

  return (
    <Box sx={{height: 'calc(100vh - 250px)'}} mt={1}>
      <DataGrid
        disableColumnMenu
        disableColumnSelector
        className='custom-data-grid cmc_new_grid h-654 text-white'
        rows={data?.data ?? []}
        columns={columns}
        loading={isLoading}
        rowCount={data?.meta?.total ?? 0}
        paginationModel={pagination}
        pageSizeOptions={[10, 25, 50]}
        paginationMode='server'
        onPaginationModelChange={setPagination}
        onSortModelChange={handleSortModelChange}
        columnVisibilityModel={{
          request_type: true,
          actions: canEdit,
        }}
        disableRowSelectionOnClick
        slots={{
          pagination: DataGridCustPagination,
          noRowsOverlay: () => (
            <CustomNoRowsOverlay
              imageSrc={IconAdd}
              message='No timed messages available'
              buttonText='Create New'
              IconButton={IconAddEvent}
              onButtonClick={toggleCreateDialog}
            />
          ),
        }}
        slotProps={{
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true,
          },
        }}
      />
    </Box>
  );
};

const ActionDetails = ({
  params,
  setViewId,
  setStatus,
}: {
  params: GridRenderCellParams;
  setViewId: (val: number) => void;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [dialogType, setDialogType] = useState('');
  const companyId = useSelector(companyIdSelector);
  return (
    <Stack
      className='w-full'
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      spacing={1}>
      <Box
        className='flex items-center justify-center w-full'
        sx={{gap: '8px'}}>
        <UserCanAny scope='is-admin,partner,timed-message:view'>
          <IconViewButton
            onClick={() => setViewId(params.row.id)}
            title='View'
          />
        </UserCanAny>
        <UserCanAny scope='is-admin,timed-message:update'>
          <IconButton
            aria-label='edit'
            color='primary'
            title='Edit'
            onClick={() => setDialogType('update')}>
            <Edit />
          </IconButton>
        </UserCanAny>
        <UserCanAny scope='is-admin,timed-message:delete'>
          <DeleteRow
            dialog_content={
              <>
                <Typography>
                  This action will delete the following Timed Message Request "
                  {params.row.name}"
                </Typography>
                <Typography>
                  NOTE: The timed message once deleted will not be retrieved
                </Typography>
              </>
            }
            refreshQueryKey={queryKeyMessageRequestList(
              companyId,
              'timed-message',
            )}
            url={`v1/company/${companyId}/message/timed-message/${params.row.id}`}
          />
        </UserCanAny>
        {params.row.status !== 'In Progress' ? (
          <>
            <UserCanAny scope='is-admin,partner,timed-message:update'>
              {params.row.status === 'Inactive' ? (
                <IconButton
                  aria-label='close'
                  color='primary'
                  title='Restore'
                  onClick={() => setDialogType('restore')}>
                  <Restore />
                </IconButton>
              ) : (
                <IconButton
                  aria-label='close'
                  color='error'
                  title='Deactivate'
                  onClick={() => setDialogType('de-activate')}>
                  <NotInterested />
                </IconButton>
              )}
            </UserCanAny>
            <UserCanAny scope='is-admin,timed-message:create'>
              <IconButton
                onClick={() => setDialogType('duplicate')}
                title='Duplicate'>
                <ContentCopy />
              </IconButton>
            </UserCanAny>
          </>
        ) : null}
      </Box>
      {dialogType !== 'de-activate' && dialogType !== '' ? (
        <TimedMessageCreate
          onClose={() => setDialogType('')}
          timedId={Number(params.row.id)}
          type={dialogType}
          setStatus={setStatus}
        />
      ) : null}
      {dialogType === 'de-activate' ? (
        <TimedMsgDeActivate
          request_id={Number(params.row.id)}
          handleCancel={() => setDialogType('')}
        />
      ) : null}
    </Stack>
  );
};

export default TimedMsgDataGrid;
