import dayjs from 'dayjs';
import {useSelector} from 'react-redux';
import {Edit, NotInterested, Restore} from '@mui/icons-material';
import {Box, IconButton, Stack, Typography} from '@mui/material';
import React, {useCallback, useMemo, useState} from 'react';
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import {
  eventSpecificDataGridApiPath,
  useRqGetEventMessageList,
} from '../../../react_query/messages/message-requests/EventMessageRequestList';
import {useStoreSelector} from '../../../store/configstore';
import {
  companyIdSelector,
  isGTSelector,
} from '../../../store/Slices/companySlice';
import {DataGridCustPagination} from '../../../components/utlis/DataGrid/Pagination';
import EventDialog from './EventSpecificDialog';
import {useStylesed} from '../../../components/styles/commonStyled';
import {UserCanAny} from '../../../components/UserCan';
import {useUserCanAny} from '../../../hooks/useUserCan';
import {IEsmStatus} from '../../../dto/EventSpecificMessageRequest.dto';
import {IEventType} from '../../../dto/EventType.dto';
import {IDataGridSearchSort} from '../../../dto/Html.dto';
import {GridCellExpand} from '../../../components/utlis/DataGrid/ExpandCellRender';
import IconAdd from '../../../asset/icons/icon_messaging.svg';
import IconAddEvent from '../../../asset/icons/icon_addsong.svg';
import CustomNoRowsOverlay from '../../../components/utlis/DataGrid/CustomNoRowsOverlay';
import CustomModal from '../../../components/modal/CustomModal';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import EventSpecificViewMessage from './EventSpecificViewMessage';
import GridFilterHeader from '../../../components/header/GridFilterHeader';
import IconViewButton from '../../../components/Button/IconViewButton';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';
import {IStation} from '../../../dto/Station.dto';
import '../../../styles/utilities/_common.js';
import DeleteRow from '../../../components/utlis/DataGrid/DeleteRow';
import EventMessageCreate from './EventMessageCreate';
import DownloadButton from '../../../components/Button/DownloadButton';

const EventMessageDataGrid = () => {
  const isGT = useSelector(isGTSelector);
  const company_id = useStoreSelector(companyIdSelector);
  const classes = useStylesed();
  const [status, setStatus] = React.useState<IEsmStatus>('Active');
  const canUpdateStatus = useUserCanAny(
    'is-admin,partner,event-message:view,event-message:update,event-message:update-status,event-message:delete',
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [query, setQuery] = useState<IDataGridSearchSort>({
    search: '',
    page: 0,
    pageSize: 10,
    status: 'Active',
  });

  const {data, isLoading} = useRqGetEventMessageList(Number(company_id), query);

  const handleActionButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleStatus = (newValue: IEsmStatus) => {
    setStatus(newValue);
    setQuery(prev => {
      return {
        ...prev,
        status: newValue,
      };
    });
  };

  const handleSearch = useCallback((value: string) => {
    setQuery(prev => ({...prev, search: value}));
  }, []);

  const columns: GridColDef[] = useMemo(() => {
    let filteredColumns: GridColDef[] = [
      {
        field: 'preview',
        type: 'actions',
        headerName: 'Play',
        width: 80,
        align: 'center',
        renderCell: (params: GridRenderCellParams) =>
          params.row?.message?.media_track ? (
            <Box className='text-center'>
              <MusicPreviewBtn
                id={params.row.id}
                url={params.row.message?.media_track?.url}
                track_name={params.row.message?.media_track?.track_name}
                track_artist={params.row.message?.media_track?.track_artist}
              />
              <DownloadButton
                filename={params.row.message?.media_track?.file_name}
                url={params.row.message?.media_track?.url}
              />
            </Box>
          ) : null,
      },
      {
        field: 'name',
        headerName: 'Message Name',
        hideable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return <GridCellExpand value={params.row.name} />;
        },
      },
      {
        field: 'station',
        headerName: 'Station Name',
        hideable: false,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const stations = params.row.stations
            ?.map((o: IStation) => o.label || o.name)
            .join(', ');
          return (
            <GridCellExpand
              value={stations}
              width={params.colDef.computedWidth}
            />
          );
        },
      },
      {
        field: 'start_date',
        headerName: 'Start Date',
        hideable: false,
        width: 115,
        renderCell: (params: GridRenderCellParams) => {
          return dayjs(params.value).format('MM/DD/YYYY');
        },
      },
      {
        field: 'end_date',
        headerName: 'End Date',
        hideable: false,
        width: 115,
        renderCell: (params: GridRenderCellParams) => {
          return dayjs(params.value).format('MM/DD/YYYY');
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        hideable: false,
        width: 116,
        sortable: false,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Action',
        width: 280,
        sortable: false,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => {
          return <ActionDetails params={params} status={status} />;
        },
      },
    ];

    // If IsGT is true, add the School Name and Station Name columns after the 'Sport/Events' column
    if (isGT === true) {
      const eventColumnIndex = filteredColumns.findIndex(
        column => column.field === 'station',
      );

      filteredColumns.splice(
        eventColumnIndex + 1,
        0,
        {
          field: 'school',
          headerName: 'School Name',
          hideable: false,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            const schools = params.row.schools
              ?.map((o: ICompanySchool) => o.name)
              .join(', ');
            return <GridCellExpand value={schools} />;
          },
        },
        {
          field: 'event',
          headerName: 'Sport/Events',
          hideable: false,
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) => {
            const events = params.row.events
              .map((o: IEventType) => o.others_event_name ?? o.event_name)
              .join(', ');
            return <GridCellExpand value={events} />;
          },
        },
      );
    } else {
      const eventColumnIndex = filteredColumns.findIndex(
        column => column.field === 'station',
      );

      filteredColumns.splice(
        eventColumnIndex + 1,
        0,
        {
          field: 'created_at',
          headerName: 'Created Date',
          hideable: false,
          width: 200,
          renderCell: (params: GridRenderCellParams) => {
            return dayjs(params.value).format('MM/DD/YYYY hh:mm:ss A');
          },
        },
        {
          field: 'created_by',
          headerName: 'Created By',
          hideable: false,
          width: 200,
          renderCell: (params: GridRenderCellParams) => {
            return (
              <GridCellExpand
                value={params.row.created_by.name}
                width={params.colDef.computedWidth}
              />
            );
          },
        },
      );
    }

    return filteredColumns.map(column => ({
      ...column,
      filterable: false,
    }));
  }, [status, isGT]);

  const handlePagination = useCallback((paginate: GridPaginationModel) => {
    setQuery(prev => ({...prev, ...paginate}));
  }, []);

  const handleSortModelChange = (model: GridSortModel) => {
    if (model[0]) {
      setQuery(prev => ({...prev, ...model[0]}));
    }
  };

  return (
    <>
      <Box className='GridTableList'>
        <Box className={classes.r_between}>
          <GridFilterHeader
            handleSearch={handleSearch}
            handleStatus={handleStatus}
            handleActionButtonClick={handleActionButtonClick}
            status={status}
            IconAdd={IconAdd}
            query={query}
          />
        </Box>

        <Box className='h-auto'>
          <DataGrid
            disableColumnMenu
            disableColumnSelector
            className='custom-data-grid cmc_new_grid h-654 text-white'
            rows={data?.data ?? []}
            columns={columns}
            loading={isLoading}
            rowCount={data?.meta?.total ?? 0}
            paginationModel={{
              page: query.page,
              pageSize: query.pageSize,
            }}
            pageSizeOptions={[10, 25, 50]}
            paginationMode='server'
            onPaginationModelChange={handlePagination}
            sortingMode='server'
            onSortModelChange={handleSortModelChange}
            disableRowSelectionOnClick
            columnVisibilityModel={{
              event: isGT,
              actions: canUpdateStatus,
            }}
            slots={{
              pagination: DataGridCustPagination,
              noRowsOverlay: () => (
                <CustomNoRowsOverlay
                  imageSrc={IconAdd}
                  message='No event specific messages available'
                  buttonText='Create New'
                  IconButton={IconAddEvent}
                  onButtonClick={handleActionButtonClick}
                />
              ),
            }}
            slotProps={{
              columnsPanel: {
                disableHideAllButton: true,
                disableShowAllButton: true,
              },
            }}
          />
        </Box>

        {modalOpen && <EventMessageCreate onClose={handleCloseModal} />}
      </Box>
    </>
  );
};

const ActionDetails = ({
  params,
  status,
}: {
  params: GridRenderCellParams;
  status: 'Active' | 'Inactive' | 'In Progress';
}) => {
  const companyId = useSelector(companyIdSelector);
  const [isDialogOpen, setIsdialogOpen] = React.useState(false);
  const [messageRequestId, setMessageRequestId] = React.useState(0);
  const [viewModalOpen, setViewModalOpen] = React.useState(false);
  const [viewId, setViewId] = React.useState<number>(0);
  const [isEdit, setIsEdit] = useState(false);

  const handleStatus = useCallback((id: number) => {
    setMessageRequestId(id);
    setIsdialogOpen(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsdialogOpen(false);
  }, []);

  const handleCloseViewModal = () => {
    setViewModalOpen(false);
    setViewId(0);
  };

  const viewMessage = (id: number) => {
    setViewModalOpen(true);
    setViewId(id);
  };

  return (
    <Stack
      className='w-full'
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      spacing={2}>
      <Box
        className='flex items-center justify-center w-full'
        sx={{gap: '8px'}}>
        <UserCanAny scope='is-admin,partner,event-message:view'>
          <IconViewButton
            onClick={() => viewMessage(params.row.id)}
            title='View'
          />
        </UserCanAny>
        <UserCanAny scope='is-admin,event-message:delete'>
          <DeleteRow
            dialog_content={
              <>
                <Typography>
                  This action will delete the following ESM Request "
                  {params.row.name}"
                </Typography>
                <Typography>
                  NOTE: The ESM message once deleted will not be retrieved
                </Typography>
              </>
            }
            refreshQueryKey={eventSpecificDataGridApiPath(companyId)}
            url={`v1/company/${companyId}/message/event-message/${params.row.id}`}
          />
        </UserCanAny>
        <UserCanAny scope='is-admin,event-message:update'>
          <IconButton
            aria-label='edit'
            color='primary'
            title='Edit'
            onClick={() => setIsEdit(true)}>
            <Edit />
          </IconButton>
        </UserCanAny>
        {params.row.status !== 'In Progress' ? (
          <UserCanAny scope='is-admin,partner,event-message:update-status'>
            {status === 'Inactive' ? (
              <IconButton
                aria-label='restore'
                color='primary'
                title='Restore'
                onClick={() => handleStatus(params.row.id)}>
                <Restore />
              </IconButton>
            ) : (
              <IconButton
                aria-label='deactivate'
                color='error'
                title='Deactivate'
                onClick={() => handleStatus(params.row.id)}>
                <NotInterested />
              </IconButton>
            )}
          </UserCanAny>
        ) : null}
      </Box>
      {isDialogOpen ? (
        <EventDialog
          status={status}
          handleCancel={handleCancel}
          messageRequestId={messageRequestId}
        />
      ) : null}
      {isEdit ? (
        <EventMessageCreate
          onClose={() => setIsEdit(false)}
          update_id={params.row.id}
        />
      ) : null}
      <CustomModal
        open={viewModalOpen}
        onClose={handleCloseViewModal}
        title='Message Preview'
        body={<EventSpecificViewMessage id={viewId} />}
        actionButtonText='Close Modal'
        onActionButtonClick={handleCloseViewModal}
        maxwidth='740'
        customHeight='605'
      />
    </Stack>
  );
};

export default EventMessageDataGrid;
