import {Box, IconButton, Typography} from '@mui/material';
import {GridCloseIcon} from '@mui/x-data-grid';
import {IOnDemCustLib} from './OndemandStepper';
import {IStation} from '../../../dto/Station.dto';
import IconClose from '../../../asset/icons/icon_close.svg';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';
import {ISeason} from '../../contacts/messages/base/BaseMessagesReducer';
import {ICompany} from '../../../dto/Company.dto';
import {ITag} from '../../../dto/MediaTrack.dto';

interface Nameable {
  name: string;
}

function hasNameProperty<T extends object>(obj: T | null): obj is T & Nameable {
  return typeof obj === 'object' && obj !== null && 'name' in obj;
}

const CommonCusDropDown = ({
  showData,
  cancelData,
  deselectAll,
}: {
  showData:
    | IOnDemCustLib[]
    | IStation[]
    | ICompanySchool[]
    | ISeason[]
    | ITag[]
    | ICompany[];
  cancelData: (id: number) => void;
  deselectAll: () => void;
}) => {
  return (
    <Box
      className='flex items-center relative my-2'
      sx={{backgroundColor: '#182138a1'}}>
      <Box className='flex flex-wrap pr-2 px-3 pt-3 pb-1 relative  max-h-110 scrollbar overflow-auto'>
        {showData.map(data => (
          <Box
            key={data.id}
            className='flex items-center px-2 py-0 mr-1 mb-2 radius-50'
            sx={{
              backgroundColor: '#0f1526',
              border: '1px solid #4c4d4db8',
            }}>
            <Typography
              variant='body2'
              className='text-14 flex items-center flex max-w-220 ellipsis'>
              <span className='overflow-hidden ellipsis'>
                {hasNameProperty(data)
                  ? (data as IStation).label || data.name
                  : (data as ISeason).season_name}
              </span>
              <IconButton
                className='w-20'
                onClick={() => cancelData(data.id)}
                title='Cancel'>
                <GridCloseIcon
                  className='radius-100 text-white ml-2 text-16 p-0.5'
                  sx={{background: '#461212', padding: '2px'}}
                />
              </IconButton>
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        className='deselect-all absolute position-top-right w-20 cursor-pointer'
        onClick={deselectAll}>
        <img src={IconClose} alt='close' height='20px' />
      </Box>
    </Box>
  );
};

export default CommonCusDropDown;
