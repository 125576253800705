import {Box} from '@mui/system';
import {Button, ButtonGroup, Grid, Stack, Typography} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import MusicPlaylistNavButtons from '../../../components/music/MusicPlaylistNavButtonsCmpt';
import ListSuperGenreCmpt, {
  GenreActionCompt,
  GenreResetButton,
} from './custom-genre-comp/PartnerCustomGenreCmpt';
import {useRqGenres} from '../../../react_query/music_genres/ListGenre';
import {IMusicGenre} from '../../../dto/MusicGenre.dto';
import {
  useRqPartnerGenreDetails,
  useRqProductDefaultGenre,
} from '../../../react_query/partner-custom-genres/PartnerCustomGenres';
import {
  companyIdSelector,
  productLevelSelector,
  productSelector,
} from '../../../store/Slices/companySlice';
import {useStoreSelector} from '../../../store/configstore';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import {apiPost} from '../../../axiosConfig';
import {UserCanAny} from '../../../components/UserCan';
import {useUserCanAny} from '../../../hooks/useUserCan';
import MusicTypePane from './MusicTypePane';

export type IGenreActionProp = IMusicGenre & {
  colorClassName: string;
};
export type IGenreActionKeyProp = {
  [key: number]: IMusicGenre;
};
const PartnerCustomGenrePage = () => {
  const navigate = useNavigate();
  const companyId: number = useStoreSelector(companyIdSelector);
  const productData = useSelector(productSelector);
  const productLevel = useSelector(productLevelSelector);
  const [showList, setShowList] = useState<IGenreActionProp[]>([]);
  const [hideList, setHideList] = useState<IGenreActionProp[]>([]);
  const [showSelectedId, setShowSelectedId] = useState<number[]>([]);
  const [hideSelectedId, setHideSelectedId] = useState<number[]>([]);
  const [apiState, setApiState] = useState({status: '', error: null});
  const [routeType, setRouteType] = useState<string>('');
  const [defaultGenreIds, setDefaultGenreIds] = useState<number[]>([]);
  const [selectedSuperGenres, setSelectedSuperGenres] = useState<number[]>([]);

  const {data, isFetching, isError} = useRqGenres();

  const {
    data: defaultGenreData,
    isFetching: defaultGenreIsFetching,
    isError: defaultGenreIsError,
  } = useRqProductDefaultGenre(productData?.id ?? 0);

  const {
    data: partnerGenreData,
    isFetching: partnerGenreIsFetching,
    isError: partnerGenreIsError,
  } = useRqPartnerGenreDetails(companyId ?? 0);

  const handleSubmit = useCallback(() => {
    setApiState({status: 'pending', error: null});
    setRouteType('update');
    const route = partnerGenreData?.id
      ? `/v1/company/${companyId}/genre-management?_method=PUT`
      : `/v1/company/${companyId}/genre-management`;

    let payload = {};

    if (partnerGenreData?.id) {
      payload = {
        product_id: productData?.id ?? 0,
        company_genre_id: partnerGenreData?.id ?? 0,
        showGenreIds: showList.map(val => val.id),
        hideGenreIds: hideList.map(val => val.id),
      };
    } else {
      payload = {
        product_id: productData?.id ?? 0,
        showGenreIds: showList.map(val => val.id),
        hideGenreIds: hideList.map(val => val.id),
      };
    }

    apiPost(route, payload)
      .then(() => {
        setApiState({status: 'success', error: null});
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
    return 1;
  }, [companyId, hideList, partnerGenreData?.id, productData?.id, showList]);

  const handleReset = useCallback(() => {
    setApiState({status: 'pending', error: null});
    setRouteType('reset');

    apiPost(`/v1/company/${companyId}/genre-management?_method=DELETE`)
      .then(() => {
        setApiState({status: 'success', error: null});
      })
      .catch(error => {
        setApiState({status: 'error', error});
      });
    return 1;
  }, [companyId]);

  // option to edit the form when api is failed
  const handleEdit = useCallback(() => {
    setApiState({status: '', error: null});
  }, []);

  const onClose = () => navigate(0);

  useEffect(() => {
    if (defaultGenreData && !defaultGenreIsFetching && !defaultGenreIsError) {
      setDefaultGenreIds(defaultGenreData.map(value => value.id));
    }
  }, [defaultGenreData, defaultGenreIsError, defaultGenreIsFetching]);

  useEffect(() => {
    const selectedIds: number[] = [];
    if (showList.length > 0) {
      showList.forEach(value => {
        if (
          value.super_genre_id &&
          !selectedIds.includes(value.super_genre_id)
        ) {
          selectedIds.push(value.super_genre_id);
        }
      });
      setSelectedSuperGenres(selectedIds);
    }
  }, [showList]);

  useEffect(() => {
    if (
      defaultGenreIds &&
      partnerGenreData &&
      partnerGenreData.id === 0 &&
      !partnerGenreIsFetching &&
      !partnerGenreIsError &&
      data &&
      !isFetching &&
      !isError &&
      defaultGenreData &&
      !defaultGenreIsFetching &&
      !defaultGenreIsError
    ) {
      const showData: IGenreActionProp[] = [];
      const hideData: IGenreActionProp[] = [];
      data.forEach(value => {
        if (!defaultGenreIds.includes(value.id)) {
          hideData.push({...value, colorClassName: 'list-bg-color-grey'});
        }
      });

      defaultGenreData.map(value => {
        showData.push({...value, colorClassName: 'list-bg-color-green'});
      });

      setShowList(showData);
      setHideList(hideData);
    } else if (
      defaultGenreIds &&
      partnerGenreData &&
      !partnerGenreIsFetching &&
      !partnerGenreIsError &&
      data &&
      !isFetching &&
      !isError
    ) {
      const keyGenres: IGenreActionKeyProp = {};
      const showData: IGenreActionProp[] = [];
      const hideData: IGenreActionProp[] = [];

      data.forEach(value => {
        keyGenres[value.id] = {...value};
      });

      partnerGenreData?.genres?.forEach(value => {
        if (value.pivot.is_visible === 1) {
          if (defaultGenreIds.includes(value.id)) {
            if (
              keyGenres[value.id] &&
              keyGenres[value.id]?.id &&
              keyGenres[value.id]?.name &&
              keyGenres[value.id]?.super_genre_id
            ) {
              showData.push({
                id: keyGenres[value.id]?.id ?? 0,
                name: keyGenres[value.id]?.name ?? '',
                super_genre_id: keyGenres[value.id]?.super_genre_id ?? 0,
                colorClassName: 'list-bg-color-green',
              });
            }
          } else {
            if (
              keyGenres[value.id] &&
              keyGenres[value.id]?.id &&
              keyGenres[value.id]?.name &&
              keyGenres[value.id]?.super_genre_id
            ) {
              showData.push({
                id: keyGenres[value.id]?.id ?? 0,
                name: keyGenres[value.id]?.name ?? '',
                super_genre_id: keyGenres[value.id]?.super_genre_id ?? 0,
                colorClassName: 'list-bg-color-orange',
              });
            }
          }
        } else {
          if (defaultGenreIds.includes(value.id)) {
            if (
              keyGenres[value.id] &&
              keyGenres[value.id]?.id &&
              keyGenres[value.id]?.name &&
              keyGenres[value.id]?.super_genre_id
            ) {
              hideData.push({
                id: keyGenres[value.id]?.id ?? 0,
                name: keyGenres[value.id]?.name ?? '',
                super_genre_id: keyGenres[value.id]?.super_genre_id ?? 0,
                colorClassName: 'list-bg-color-red',
              });
            }
          } else {
            if (
              keyGenres[value.id] &&
              keyGenres[value.id]?.id &&
              keyGenres[value.id]?.name &&
              keyGenres[value.id]?.super_genre_id
            ) {
              hideData.push({
                id: keyGenres[value.id]?.id ?? 0,
                name: keyGenres[value.id]?.name ?? '',
                super_genre_id: keyGenres[value.id]?.super_genre_id ?? 0,
                colorClassName: 'list-bg-color-grey',
              });
            }
          }
        }
      });

      setShowList(showData);
      setHideList(hideData);
    }
  }, [
    data,
    defaultGenreData,
    defaultGenreIds,
    defaultGenreIsError,
    defaultGenreIsFetching,
    isError,
    isFetching,
    partnerGenreData,
    partnerGenreIsError,
    partnerGenreIsFetching,
  ]);

  return (
    <Box mt={1} className='p-5'>
      <Stack direction='row' justifyContent='flex-end'>
        <MusicPlaylistNavButtons type={'partner-genre-management'} />
      </Stack>
      <Stack direction='row' justifyContent='center' spacing={2} my={2}>
        {productData ? (
          <div>
            {productLevel ? (
              <Typography variant='h5'>{`${productData.cmc_product} --> ${productData.primary} --> ${productLevel}`}</Typography>
            ) : (
              <Typography variant='h5'>{`${productData.cmc_product} --> ${productData.primary}`}</Typography>
            )}
          </div>
        ) : null}
        {partnerGenreData &&
        !partnerGenreIsFetching &&
        !partnerGenreIsError &&
        partnerGenreData.id ? (
          <>
            <Button variant='contained' color='error'>
              Custom
            </Button>
            <UserCanAny scope={'is-admin,partner-genre-management:delete'}>
              <GenreResetButton handleReset={handleReset} />
            </UserCanAny>
          </>
        ) : (
          <Button variant='contained' color='warning'>
            Default
          </Button>
        )}
      </Stack>
      <Grid
        container
        spacing={2}
        maxHeight='calc(100vh - 300px)'
        overflow={'auto'}
        position={'relative'}>
        <Grid item xs={4} className='h-full'>
          <ListSuperGenreCmpt selectedSuperGenres={selectedSuperGenres} />
          <UserCanAny scope={'is-admin,staff'}>
            <MusicTypePane />
          </UserCanAny>
        </Grid>
        <Grid item xs={8} className='h-full'>
          {showList && hideList && (
            <GenreActionCompt
              showList={showList}
              setShowList={setShowList}
              hideList={hideList}
              setHideList={setHideList}
              showSelectedId={showSelectedId}
              setShowSelectedId={setShowSelectedId}
              hideSelectedId={hideSelectedId}
              setHideSelectedId={setHideSelectedId}
            />
          )}
        </Grid>
      </Grid>

      <Stack
        direction='column'
        alignItems={'center'}
        justifyContent='center'
        mt={2}>
        <ButtonGroup
          disableElevation
          variant='contained'
          aria-label='Disabled elevation buttons'
          sx={{width: '300px', mb: 0.5}}>
          <Button
            variant='contained'
            disabled={
              !useUserCanAny(
                'is-admin,partner-genre-management:update,partner-genre-management:create',
              )
            }
            color='success'
            onClick={handleSubmit}
            fullWidth>
            Update
          </Button>
          <Button variant='contained' color='error' onClick={onClose} fullWidth>
            Cancel
          </Button>
        </ButtonGroup>
        {partnerGenreData &&
          partnerGenreData.updated_by &&
          !partnerGenreIsFetching &&
          !partnerGenreIsError && (
            <i>{`${partnerGenreData.updated_by.name} ${dayjs(
              partnerGenreData.update_at,
            ).format('MM/DD/YYYY hh:mm:ss A')}`}</i>
          )}
      </Stack>
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={routeType === 'reset' ? handleReset : handleSubmit}
          onClose={onClose}
          onEdit={handleEdit}
        />
      )}
    </Box>
  );
};

export default PartnerCustomGenrePage;
