import {Box, Tab, Tabs} from '@mui/material';
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {useParams} from 'react-router-dom';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {IMediaTrack} from '../../../dto/MediaTrack.dto';
import InputSearch from '../../../components/styles/InputSearch';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import {SongSelectionRemoveButton} from '../../../components/Button/SongSelectionRemoveButton';
import {SongSelectionAddButton} from '../../../components/Button/SongSelectionAddButton';
import {GridDurationColumn} from '../../../components/grid/GridColumns';
import {
  IConditionalOperators,
  IDataGridProSearchSort,
  defaultDataGridProSearchSort,
} from '../../../dto/Html.dto';
import {tabsStyleRadioMode} from '../../../components/styles/TabStyles';
import {DataGridCustPagination} from '../../../components/utlis/DataGrid/Pagination';
import {useRqMediaTracksWithTokenPaginate} from '../../../react_query/media_tracks/ListMediaTrackPaginatPro';

type Props = {
  selectedTracks: IMediaTrack[];
  onSelect: (track: IMediaTrack) => void;
  onDeselect: (track: IMediaTrack) => void;
  isAddDisabled?: boolean;
};

export const WalkupMusicSelectionTable = ({
  selectedTracks,
  onSelect,
  onDeselect,
  isAddDisabled,
}: Props) => {
  const {token} = useParams();
  const [selectedTrackIds, setSelectedTrackIds] = useState<number[]>([]);
  const [queryTab, setQueryTab] = useState<string>('all');
  const [query, setQuery] = React.useState<
    IDataGridProSearchSort &
      (
        | {
            track_length: number;
            track_length_condition: IConditionalOperators;
          }
        | {track_length?: undefined; track_length_condition?: undefined}
      )
  >({
    ...defaultDataGridProSearchSort,
    track_category: 2,
  });
  const {data, isLoading} = useRqMediaTracksWithTokenPaginate(
    token ?? '',
    query,
  );

  const handleSearch = useCallback((val: string) => {
    setQuery(prev => ({
      ...prev,
      search: val,
    }));
  }, []);
  const handlePagination = useCallback((paginate: GridPaginationModel) => {
    setQuery(prev => ({...prev, ...paginate}));
  }, []);

  const handleSortModelChange = (model: GridSortModel) => {
    setQuery(prev => ({...prev, sort: model}));
  };

  const handleSongAdd = useCallback(
    (musicTrack: IMediaTrack) => {
      onSelect(musicTrack);
    },
    [onSelect],
  );

  const handleSongRemove = useCallback(
    (musicTrack: IMediaTrack) => {
      onDeselect(musicTrack);
    },
    [onDeselect],
  );

  const handleHitterPithcerToggle = useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      setQuery(prev => {
        const updatedQuery = {...prev};
        updatedQuery.track_category = 2;
        if (newValue === 'all') {
          delete updatedQuery.track_length;
          delete updatedQuery.track_length_condition;
        } else {
          updatedQuery.track_length = 45;
          updatedQuery.track_length_condition =
            newValue === 'clip' ? 'clip' : newValue === 'hitter' ? 'lte' : 'gt';
          updatedQuery.track_category = newValue === 'clip' ? 4 : 2; // 4-clip, 2-walkup-library
        }

        return updatedQuery;
      });

      setQueryTab(newValue);
    },
    [setQuery],
  );

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'track_artist',
          headerName: 'Artist',
          flex: 1,
        },
        {
          field: 'track_name',
          headerName: 'Song Title',
          flex: 1,
        },
        GridDurationColumn,
        {
          field: 'type',
          headerName: 'Type',
          flex: 1,
          sortable: false,
          renderCell: (params: GridRenderCellParams) =>
            params.row.duration > 45 ? 'Pitcher' : 'Hitter',
        },
        {
          field: 'url',
          type: 'actions',
          headerName: 'Play',
          width: 54,
          sortable: false,
          renderCell: (params: GridRenderCellParams<IMediaTrack>) =>
            params.row.url ? (
              <MusicPreviewBtn
                id={params.row.id}
                url={params.row.url}
                track_name={params.row.track_name}
                track_artist={params.row.track_artist}
              />
            ) : null,
        },

        {
          type: 'actions',
          field: 'actions',
          headerName: '',
          hideable: false,
          sortable: false,
          width: 125,
          renderCell: (params: GridRenderCellParams<IMediaTrack>) =>
            selectedTrackIds.includes(params.row.id) ? (
              <SongSelectionRemoveButton
                onClick={() => handleSongRemove(params.row)}
              />
            ) : (
              <SongSelectionAddButton
                onClick={() => handleSongAdd(params.row)}
                disabled={isAddDisabled}
              />
            ),
        },
      ].map(column => ({
        ...column,
        filterable: false,
      })),
    [handleSongAdd, handleSongRemove, isAddDisabled, selectedTrackIds],
  );

  useEffect(() => {
    setSelectedTrackIds(selectedTracks.map(o => o.id));
  }, [selectedTracks]);

  return (
    <>
      <Box className='flex justify-between'>
        <Tabs
          value={queryTab}
          onChange={handleHitterPithcerToggle}
          variant='scrollable'
          sx={tabsStyleRadioMode}
          aria-label='tabs'>
          <Tab value='hitter' label='Hitter' />
          <Tab value='all' label='Show All' />
          <Tab value='pitcher' label='Pitcher' />
          <Tab value='clip' label='Custom Clips' />
        </Tabs>

        <InputSearch
          className='max-w-300 block ml-auto'
          placeholder='Search Song'
          value={`${query.search}`}
          onChange={handleSearch}
        />
      </Box>
      <Box sx={{mt: 1, height: 500}}>
        <DataGridPro
          rows={data?.data ?? []}
          columns={columns}
          loading={isLoading}
          rowCount={data?.total ?? 0}
          pageSizeOptions={[10, 25, 50]}
          paginationModel={{page: query.page, pageSize: query.pageSize}}
          paginationMode='server'
          pagination={true}
          onPaginationModelChange={handlePagination}
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          density='compact'
          disableRowSelectionOnClick
          disableColumnMenu
          slots={{
            pagination: DataGridCustPagination,
          }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
          }}
        />
      </Box>
    </>
  );
};
