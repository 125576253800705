import {lazy} from 'react';
import {Route, Routes} from 'react-router-dom';
import AuthUsers from '../components/layouts/AuthUsers';
import UnAuthUsers from '../components/layouts/UnAuthUsers';
import PsmHomePage from '../pages/Psm/PsmHomePage';

import GenreSelectionStudentsForm from '../pages/music/radio-mode/songs-genre-selection/student_selection_form/GenreSelection';
import ImpersonateCompany from '../pages/company-list/ImpersonateCompany';
import ImpersonateUser from '../pages/company-list/ImpersonateUser';

import GuestRoutes from './GuestRoutes';

import MessagesRoutes from './MessagesRoutes';
import SponsorRoutes from './sponsor/SponsorRoutes';
import SponsorshipUpdateBySponsor from '../pages/sponsor/SponsorshipUpdateBySponsor';
import SponsorRequestDetailUpdate from '../pages/sponsor/SponsorRequestDetailUpdate';
import {CanRouteAny, UserCanAny} from '../components/UserCan';
import NotFoundView from '../pages/PageNotFound';

import FileReUploadProcess from '../pages/admin/PartnerExperience/PSM/onboard/FileReUploadProcess';
import PartnerCustomGenrePage from '../pages/music/partner-custom-genre/PartnerCustomGenre';
import AuthUsersWithoutHeader from '../components/layouts/AuthUsersWithoutHeader';
import TNNSponsorSelectedCampaign from '../pages/admin/tnn-control/TNNSponsorSelectedCampaign';
import ParticipationFormOption from '../pages/participation-form/ParticipationFormOption';
import OnboardingRoutes from './OnboardingRoutes';
import {WalkupStudentSelectionRoutes} from './general/StudentSelectionRoutes';
import MiniPlayerBaseRoute from '../components/layouts/MiniPlayerBaseRoute';

const MusicRoutes = lazy(() => import('./music/MusicRoutes'));

const AdminRoutes = lazy(() => import('./AdminRoutes'));
const TaskRoutes = lazy(() => import('./TaskRoutes'));

const ReferralView = lazy(() => import('../pages/referral/ReferralView'));

const StudentSelectionRoutes = lazy(
  () => import('./general/StudentSelectionRoutes'),
);
const Unauth = lazy(() => import('../pages/Dashboard/general/login/Unauth'));
const OnboardParternRoutes = lazy(() => import('./contacts/onboarding'));
const Login = lazy(() => import('../auth/Login'));
const ForgetPassword = lazy(() => import('../auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../auth/ResetPassword'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const MyTasks = lazy(
  () => import('../pages/Dashboard/general/my_tasks/MyTask'),
);
const GTMessageRecording = lazy(
  () => import('../pages/admin/CMS_OPS/VoiceArtist/GT_messaging/Recording'),
);
const GTMessageReRecording = lazy(
  () => import('../pages/admin/CMS_OPS/VoiceArtist/GT_messaging/Re-Recording'),
);
const GTMessageAudioVerify = lazy(
  () =>
    import(
      '../pages/admin/PartnerExperience/PSS/GTMessage/AudioVerify/ProcessingFinalAudio'
    ),
);
const MusicSchoolSelection = lazy(
  () => import('../pages/music/schoolSelection/SchoolSelection'),
);
const MusicAddOrBrowse = lazy(() => import('../pages/music/MusicAddOrBrowse'));
const MusicChooseOrFromForm = lazy(
  () => import('../pages/music/MusicChooseOrFromForm'),
);
const MusicTypeSelection = lazy(
  () => import('../pages/music/MusicTypeSelection'),
);
const RadioModeEventsSelection = lazy(
  () => import('../pages/music/radio-mode/EventsSelection'),
);
const SelectionFormRM = lazy(
  () => import('../pages/music/radio-mode/RM_SelectionForm'),
);
const GenreSelectionRM = lazy(
  () =>
    import('../pages/music/radio-mode/songs-genre-selection/GenreSelection'),
);
const GenreSelectionReviewRM = lazy(
  () =>
    import(
      '../pages/music/radio-mode/songs-genre-selection/GenreSelectionReview'
    ),
);
const SongsSelectionRM = lazy(
  () =>
    import('../pages/music/radio-mode/songs-direct-selection/MusicSelection'),
);
const MusicSelectionReviewRM = lazy(
  () =>
    import(
      '../pages/music/radio-mode/songs-direct-selection/MusicSelectionReview'
    ),
);
const RMBothSelectionReview = lazy(
  () =>
    import(
      '../pages/music/radio-mode/songs-direct-selection/RMBothSelectionReview'
    ),
);

const TutorialsPartnerView = lazy(
  () => import('../pages/partners/tutorials/TutorialsPartnerView'),
);

const PssView = lazy(() => import('../pages/Psm/PssView'));
const PeopleManagementList = lazy(
  () => import('../pages/partners/people_management/PeopleManagementList'),
);

const TutorialManagement = lazy(
  () => import('../pages/admin/tutorial_management/TutorialManagement'),
);

const MusicReviewReviewerForm = lazy(
  () => import('../pages/music/music-review/MusicReviewReviewerForm'),
);

const ProcessingCenterView = lazy(
  () => import('../pages/Processing-center/ProcessingCenterView'),
);

const PartnerKnowledgeBaseCmpt = lazy(
  () =>
    import(
      '../pages/admin/knowledge-base-management-admin/PartnerKnowledgeBase'
    ),
);

const Routers = () => {
  return (
    <Routes>
      <Route element={<MiniPlayerBaseRoute />}>
        <Route element={<GuestRoutes />}>
          <Route
            path='student-selection/*'
            element={<StudentSelectionRoutes />}
          />
          <Route
            path='walkup-student-selection/*'
            element={<WalkupStudentSelectionRoutes />}
          />
        </Route>

        <Route path='/sponsor/link' element={<SponsorshipUpdateBySponsor />} />
        <Route
          path='/sponsor/reupload-detail'
          element={<SponsorRequestDetailUpdate />}
        />
        <Route
          path='/sponsor/content-verify'
          element={<SponsorRequestDetailUpdate />}
        />
        <Route
          path='/sponsor/audio-verify'
          element={<SponsorRequestDetailUpdate />}
        />
        <Route
          path='/sponsor/reupload-file'
          element={<SponsorRequestDetailUpdate />}
        />

        <Route element={<UnAuthUsers />}>
          <Route path='/sign-in' element={<Login />} />
          <Route path='/forgot-password' element={<ForgetPassword />} />
          <Route path='guest' element={<Unauth />} />
          <Route path='/password-reset' element={<ResetPassword />} />
          <Route path='/auth/login' element={<Login />} />
        </Route>
        <Route element={<AuthUsersWithoutHeader />}>
          <Route path='tutorials'>
            <Route index element={<TutorialsPartnerView />} />
          </Route>
          <Route
            path='/participation-form'
            element={
              <CanRouteAny scope='is-admin,partner,staff'>
                <ParticipationFormOption />
              </CanRouteAny>
            }
          />
          <Route
            path='tnn/sponsor/details'
            element={<TNNSponsorSelectedCampaign />}
          />
          <Route path='music/*' element={<MusicRoutes />} />
          <Route
            path='people-management'
            element={
              <CanRouteAny scope='is-admin,partner-admin,partner-primary-contact,partner-accounts:list,partner-accounts:create,partner-accounts:update'>
                <PeopleManagementList />
              </CanRouteAny>
            }
          />

          <Route path='messages/*' element={<MessagesRoutes />} />
          <Route path='referral'>
            <Route
              index
              element={
                <CanRouteAny scope='is-admin,partner,referral:list'>
                  <ReferralView />
                </CanRouteAny>
              }
            />
          </Route>
          <Route
            path='knowledge-base'
            element={
              <CanRouteAny scope='is-admin,partner,knowledge-base-partner:list'>
                <PartnerKnowledgeBaseCmpt />
              </CanRouteAny>
            }
          />
          <Route path='sponsor/*' element={<SponsorRoutes />} />
        </Route>
        <Route element={<AuthUsers />}>
          <Route path='/' element={<Dashboard />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route
            path='partner-impersonate/:companyId'
            element={<ImpersonateCompany />}
          />
          <Route
            path='user-impersonate/:userId'
            element={
              <UserCanAny scope='is-admin,impersonate-user'>
                <ImpersonateUser />
              </UserCanAny>
            }
          />
          <Route path='/my-tasks' element={<MyTasks />} />

          {/* Tasks */}
          <Route path='task/*' element={<TaskRoutes />} />

          <Route path='/music'>
            <Route index element={<MusicAddOrBrowse />} />
            <Route
              path='manage-genre'
              element={
                <UserCanAny scope='is-admin,partner-genre-management:list,partner-genre-management:create,partner-genre-management:update,partner-genre-management:delete'>
                  <PartnerCustomGenrePage />
                </UserCanAny>
              }
            />
            <Route path='add'>
              <Route index element={<MusicTypeSelection path='add' />} />
              <Route path='radio-mode'>
                <Route index element={<MusicSchoolSelection />} />
                <Route path='choose'>
                  <Route index element={<MusicChooseOrFromForm />} />
                  <Route path='events'>
                    <Route
                      index
                      element={
                        <RadioModeEventsSelection nextPage='selection-form' />
                      }
                    />
                    <Route path='selection-form'>
                      <Route index element={<SelectionFormRM />} />
                      <Route path='genre'>
                        <Route index element={<GenreSelectionRM />} />
                        <Route
                          path='review'
                          element={<GenreSelectionReviewRM />}
                        />
                      </Route>
                      <Route path='songs'>
                        <Route index element={<SongsSelectionRM />} />
                        <Route path='review'>
                          <Route index element={<MusicSelectionReviewRM />} />
                          <Route
                            path='both'
                            element={<RMBothSelectionReview />}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route path='student-selection'>
                  <Route path='events'>
                    <Route
                      index
                      element={<RadioModeEventsSelection nextPage='genres' />}
                    />
                    <Route path='genres'>
                      <Route index element={<GenreSelectionStudentsForm />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path='psm'>
            <Route index element={<PsmHomePage />} />
            <Route path=':id' element={<PssView />} />
          </Route>
          <Route path='/partner/*' element={<OnboardingRoutes />} />

          <Route path='contacts'>
            <Route path='onboarding/*' element={<OnboardParternRoutes />} />
          </Route>

          <Route path='CMS-OPS'>
            <Route path='VoiceArtist'>
              <Route
                path='recording/edit-message/:company_id/:edit_message_request_id/:message_type'
                element={<GTMessageRecording />}
              />
              <Route
                path='re-recording/edit-message/:company_id/:edit_message_request_id/:message_type'
                element={<GTMessageReRecording />}
              />
            </Route>
          </Route>

          <Route path='PartnerExperience'>
            <Route path='PSM'>
              <Route
                path='reupload-audio/:task_id/:message_type'
                element={<FileReUploadProcess />}
              />
            </Route>
            <Route path='PSS'>
              <Route
                path='edit-message/verify-audio/:company_id/:edit_message_request_id/:message_type'
                element={<GTMessageAudioVerify />}
              />
            </Route>
          </Route>

          <Route path='tutorial-management'>
            <Route
              index
              element={
                <CanRouteAny scope='is-admin,tutorial:list,tutorial:create,tutorial:update-status'>
                  <TutorialManagement />
                </CanRouteAny>
              }
            />
          </Route>
          <Route path='tutorials'>
            <Route index element={<TutorialsPartnerView />} />
          </Route>
          <Route path='tutorial-management'>
            <Route index element={<TutorialManagement />} />
          </Route>

          <Route path='admin/*' element={<AdminRoutes />} />
          <Route path='processing-center'>
            <Route index element={<ProcessingCenterView />} />
          </Route>

          <Route path='music-review-reviewer-form/:song_request_id'>
            <Route index element={<MusicReviewReviewerForm />} />
          </Route>
        </Route>
        {/* Put all routes above only. not found page route. */}
        <Route path='not-found' element={<NotFoundView />} />
        <Route path='*' element={<NotFoundView />} />
      </Route>
    </Routes>
  );
};

export default Routers;
