import {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {
  Box,
  TextField,
  Stack,
  Autocomplete,
  styled,
  Button,
  IconButton,
} from '@mui/material';
import IconAddSong from '../../asset/icons/icon_addsong.svg';
import MusicPreviewBtn from '../../components/music/MusicPreviewBtn';
import {getAudioDurationFromFile} from '../../components/utlis/Audio/GetAudioDuration';
import {Warning} from '@mui/icons-material';

// number count select
const options = [1, 2, 3, 4];

export const MultipleFileUploadCmpt = ({
  selectedOption,
  setSelectedOption,
  multipleFileUpload,
  setMultipleFileUpload,
  isDisabled = false,
}: {
  selectedOption: number | null;
  setSelectedOption: (selectedOption: number | null) => void;
  multipleFileUpload: IMultipleFileUploadProp[];
  setMultipleFileUpload: Dispatch<SetStateAction<IMultipleFileUploadProp[]>>;
  isDisabled?: boolean;
}) => {
  const handleChange = useCallback(
    (val: number | null) => {
      setSelectedOption(val);
    },
    [setSelectedOption],
  );
  return (
    <Box>
      <Autocomplete
        value={selectedOption}
        className='w-300 mb-3'
        options={options}
        autoHighlight
        disabled={isDisabled}
        getOptionLabel={option => option.toString()}
        onChange={(
          _: React.SyntheticEvent<Element, Event>,
          value: number | null,
        ) => {
          handleChange(value ?? null);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label='File Upload Limit'
            inputProps={{
              ...params.inputProps,
            }}
          />
        )}
      />
      {selectedOption && selectedOption > 0 ? (
        <UploadMultipleFiles
          multipleFileUpload={multipleFileUpload}
          setMultipleFileUpload={setMultipleFileUpload}
        />
      ) : null}
    </Box>
  );
};

export type IMultipleFileUploadProp = {
  id: string;
  file?: File;
  url?: string;
  fileName: string;
};
export const UploadMultipleFiles = ({
  multipleFileUpload,
  setMultipleFileUpload,
}: {
  multipleFileUpload: IMultipleFileUploadProp[];
  setMultipleFileUpload: Dispatch<SetStateAction<IMultipleFileUploadProp[]>>;
}) => {
  const handleChange = useCallback(
    (value: File | undefined, id: string) => {
      setMultipleFileUpload(prev => {
        const newVal = prev.map(val => {
          if (val.id === id && value) {
            return {
              ...val,
              fileName:
                val.fileName.length > 0
                  ? val.fileName
                  : value.name.slice(0, -4),
              url: URL.createObjectURL(value),
              file: value,
            };
          }
          return val;
        });
        return newVal;
      });
    },
    [setMultipleFileUpload],
  );

  const handleTextChange = useCallback(
    (value: string, id: string) => {
      setMultipleFileUpload(prev => {
        const newVal = prev.map(val => {
          if (val.id === id) {
            return {
              ...val,
              fileName: value.trimEnd(),
            };
          }
          return val;
        });
        return newVal;
      });
    },
    [setMultipleFileUpload],
  );

  return (
    <Box>
      {multipleFileUpload.map(val => (
        <Stack
          key={val.id}
          direction={'row'}
          alignItems={'center'}
          className='mb-3'
          spacing={1}>
          <TextField
            className='radius-10 w-300'
            id='outlined-multiline-static'
            placeholder='Label'
            value={val.fileName}
            onChange={event => handleTextChange(event.target.value, val.id)}
            rows={4}
          />
          <MultipleUplaodButton id={val.id} handleChange={handleChange} />
          {val.file && val.url ? (
            <MusicPreviewBtn
              id={val.url}
              url={val.url}
              track_name={val.fileName}
              track_artist={val.fileName}
            />
          ) : null}
        </Stack>
      ))}
    </Box>
  );
};
const MultipleUplaodButton = ({
  id,
  handleChange,
  disabled = false,
}: {
  id: string;
  handleChange: (value: File | undefined, id: string) => void;
  disabled?: boolean;
}) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const [fileDuration, setFileDuration] = useState(0);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0] as File;
        const duration = await getAudioDurationFromFile(file);
        setFileDuration(duration);
        if (duration / 60 <= 5) {
          handleChange(file, id);
        }
      }
    },
    [handleChange, id],
  );

  return (
    <Stack direction='row' alignItems='center'>
      <Button
        variant='contained'
        color='primary'
        disabled={disabled}
        component='label'
        startIcon={<img src={IconAddSong} alt='Create Task Icon' />}>
        Upload File
        <VisuallyHiddenInput
          type='file'
          accept='audio/*'
          onChange={handleFileChange}
        />
      </Button>
      {fileDuration / 60 >= 5 && (
        <IconButton color='warning' title='Max Song Duration 5 minutes'>
          <Warning />
        </IconButton>
      )}
    </Stack>
  );
};
