import React, {lazy} from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from '../pages/PageNotFound';
import PartnerReducer, {
  initialServices,
} from '../pages/partners/partner_form/PartnerReducer';
import PartnerContactReducer, {
  initialOnboardContact,
} from '../pages/partners/partner_form/PartnerContactReducer';

const ProductPartner = lazy(
  () => import('../pages/partners/partner/ProductPartner'),
);
const SearchPartner = lazy(
  () => import('../pages/partners/searchPartner/SearchPartner'),
);
const NewPartner = lazy(
  () => import('../pages/partners/partner_form/NewPartner'),
);
const PartnerContacts = lazy(
  () => import('../pages/partners/partner_form/ParnterContacts'),
);
const AddOnDetails = lazy(
  () => import('../pages/partners/add-on/AddOnDetailsPage'),
);

export default function OnboardingRoutes() {
  return (
    <Routes>
      <Route index element={<ProductPartner />} />
      <Route path='new/*' element={<NewOnboardingPartner />} />
      <Route path=':company_id/add-on/*' element={<AddOnPartner />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

const NewOnboardingPartner = () => {
  const [state, dispatch] = React.useReducer(PartnerReducer, initialServices);
  const [contactState, contactDispatch] = React.useReducer(
    PartnerContactReducer,
    initialOnboardContact,
  );

  return (
    <Routes>
      <Route
        path=''
        element={
          <SearchPartner
            dispatch={dispatch}
            contactDispatch={contactDispatch}
          />
        }
      />
      <Route
        path='form'
        element={<NewPartner state={state} dispatch={dispatch} />}
      />
      <Route
        path='contacts'
        element={
          <PartnerContacts
            onBoardState={state}
            onBoardDispatch={dispatch}
            contactState={contactState}
            contactDispatch={contactDispatch}
          />
        }
      />
    </Routes>
  );
};

const AddOnPartner = () => {
  const [state, dispatch] = React.useReducer(PartnerReducer, initialServices);
  const [contactState, contactDispatch] = React.useReducer(
    PartnerContactReducer,
    initialOnboardContact,
  );

  return (
    <Routes>
      <Route path='' element={<AddOnDetails />} />
      <Route
        path='form'
        element={<NewPartner state={state} dispatch={dispatch} />}
      />
      <Route
        path='contacts'
        element={
          <PartnerContacts
            onBoardState={state}
            onBoardDispatch={dispatch}
            contactState={contactState}
            contactDispatch={contactDispatch}
          />
        }
      />
    </Routes>
  );
};
