import dayjs, {Dayjs} from 'dayjs';
import {ISeason} from '../pages/contacts/messages/base/BaseMessagesReducer';
import {useSelector} from 'react-redux';
import {
  isGTSelector,
  isNRSelector,
  isSZSelector,
  productLevelSelector,
} from '../store/Slices/companySlice';
import {scopesSelector} from '../store/Slices/userSlice';
import {useMemo} from 'react';

export const convertTo12HourFormat = (time: string): string => {
  const timeParts = time.split('.');
  let parsedTime;
  if (timeParts.length > 1) {
    parsedTime = dayjs(time, 'HH:mm:ss.SSSSSSSSSSS');
  } else {
    parsedTime = dayjs(time, 'HH:mm:ss');
  }
  const formattedTime = parsedTime.format('hh:mm:ss A');
  return formattedTime;
};

export const formatMiniPlayerTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

export const commonDateTimeFormat = (value: Date | string) => {
  return value ? dayjs(value).format('MM/DD/YYYY hh:mm:ss A') : null;
};

export const commonDateFormat = (value: Date | Dayjs | string | null) => {
  return value ? dayjs(value).format('MM/DD/YYYY') : '';
};

export const cleanString = (input: string) => {
  const regex = /[^a-zA-Z0-9_-]/g;
  return input.replace(regex, ' ');
};

export type MessageValidationProps = {
  name: string;
  message: string;
  isAllSeason?: boolean;
  seasons?: ISeason[];
};

export const checkMessagesValid = (messages: MessageValidationProps[]) => {
  return messages.every(({name, message}) => {
    const trimmedName = name.trim();
    const trimmedMessage = message.trim();

    const isNameValid = trimmedName.length > 0 && trimmedName.length <= 50;
    const isMessageValid = trimmedMessage.length > 10;

    if (messages.length === 1) {
      return isNameValid && isMessageValid;
    } else {
      const isEmpty = trimmedName.length === 0 && trimmedMessage.length === 0;
      return (isNameValid && isMessageValid) || isEmpty;
    }
  });
};

export const availableMessageRoutes = [
  {
    permission:
      'is-admin,partner,event-message:list,event-message:create,event-message:view,event-message:update,event-message:update-status,event-message:delete',
    route: '/messages/event-message',
    SZ: true,
  },
  {
    permission:
      'is-admin,partner,on-demand-message:list,on-demand-message:create,on-demand-message:update-status,on-demand-message:view,on-demand-message:delete,update-on-demand-message',
    route: '/messages/ondemand-message',
  },
  {
    permission:
      'is-admin,partner,base-message:list,base-message:create,base-message:view,base-message:update,base-message:delete,base-message:convert-base-message',
    route: '/messages/base-message',
  },
  {
    permission:
      'is-admin,partner,timed-message:list,timed-message:create,timed-message:view,timed-message:delete',
    route: '/messages/timed-message',
    GT: false,
  },
  {
    permission:
      'is-admin,partner,wristband-schedule:list,wristband-schedule:create,wristband-schedule:update,wristband-schedule:schedule-for-date,wristband-schedule:delete-schedule-for-date',
    route: '/messages/wristband-message',
    Trampoline: true,
  },
  {
    permission:
      'is-admin,partner,stinger-message:list,stinger-message:create,stinger-message:update-status,stinger-message:delete,stinger-message:update,stinger-message:show',
    route: '/messages/stinger-message',
  },
];

const availableNonGTMusicRoutes = [
  {
    permission: 'is-admin,partner,radio-mode:list',
    route: '/music/radio-mode',
    NGT: false,
    level: 'Level 0 (ODO Only)',
  },
  {
    permission: 'is-admin,partner,music-schedules:list',
    route: '/music/music-schedules',
    GT: false,
  },
  {
    permission: 'is-admin,partner,on-demand-playlist:list',
    route: '/music/on-demand',
  },
  {
    permission: 'is-admin,partner,music-walkup:list',
    route: '/music/walkup',
    NGT: false,
  },
  {
    permission:
      'is-admin,partner,blacklisted-artist:list,blacklisted-song:list,blacklisted-bpm:view',
    route: '/music/blacklist-songs-artists',
  },
  {
    permission: 'is-admin,partner,partner-genre-management:list',
    route: '/music/manage-genre',
  },
  {
    permission: 'is-admin,template-pattern:list',
    route: '/music/template-pattern',
  },
];

export const useDefaultMusicRoute = () => {
  const isGT = useSelector(isGTSelector);
  const scopes = useSelector(scopesSelector);
  const productLevel = useSelector(productLevelSelector);
  const defaultRoute = useMemo(() => {
    const matchedRoute = availableNonGTMusicRoutes.find(o => {
      return (
        (isGT ? o.GT !== false && o.level !== productLevel : o.NGT !== false) &&
        o.permission.split(',').some(s => scopes.includes(s))
      );
    });
    return matchedRoute
      ? matchedRoute.route
      : availableNonGTMusicRoutes[0]?.route && isGT
      ? availableNonGTMusicRoutes[0]?.route
      : availableNonGTMusicRoutes[1]?.route ?? '';
  }, [scopes, isGT, productLevel]);
  return defaultRoute;
};

export const useDefaultMessageRoute = () => {
  const scopes = useSelector(scopesSelector);
  const isNR = useSelector(isNRSelector);
  const productLevel = useSelector(productLevelSelector);
  const isSZ = useSelector(isSZSelector);
  const isGT = useSelector(isGTSelector);
  let defaultRoute = useMemo(() => {
    const matchedRoute = availableMessageRoutes.find(o => {
      return (
        (isGT ? o.GT !== false : isSZ ? o.SZ === true : isNR) &&
        o.permission.split(',').some(s => scopes.includes(s))
      );
    });
    return matchedRoute
      ? matchedRoute.route
      : availableMessageRoutes[0]?.route ?? '';
  }, [scopes, isNR, isSZ, isGT]);
  if (isSZ && productLevel === 'Level 1') {
    defaultRoute = availableMessageRoutes[5]?.route ?? '';
  }
  return defaultRoute;
};
