import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {Info, NotInterested, Restore} from '@mui/icons-material';
import dayjs from 'dayjs';
import {useSelector} from 'react-redux';
import {useCallback, useMemo, useState} from 'react';
import {
  companyIdSelector,
  isGTSelector,
  isNRorSZSelector,
} from '../../../store/Slices/companySlice';
import {DataGridCustPagination} from '../../../components/utlis/DataGrid/Pagination';
import {
  IStingerMsgQueryParams,
  useRqStingerMessageList,
} from '../../../react_query/messages/message-requests/StingerMessageList';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import CustomNoRowsOverlay from '../../../components/utlis/DataGrid/CustomNoRowsOverlay';
import IconAdd from '../../../asset/icons/icon_messaging.svg';
import InputSearch from '../../../components/styles/InputSearch';
import {GridDurationColumn} from '../../../components/grid/GridColumns';
import {UserCanAny} from '../../../components/UserCan';
import {apiPut} from '../../../axiosConfig';
import DialogApiRequestStatus from '../../../components/dialogs/DialogApiRequestStatus';
import ActiveDropdown from '../../../components/dropdowns/ActiveDropdown';
import {IEsmStatus} from '../../../dto/EventSpecificMessageRequest.dto';
import CommonDialog from '../../../components/CommonDialog';
import {useUserCanAny} from '../../../hooks/useUserCan';
import CreateButton from '../../../components/Button/CreateButton';
import StingerStepper from './StingerStepper';
import DeleteRow from '../../../components/utlis/DataGrid/DeleteRow';
import {IStation} from '../../../dto/Station.dto';
import {ICompanySchool} from '../../../dto/CompanySchool.dto';
import {
  BaseMessageSeason,
  EventsFilter,
  SeasonsFilter,
} from '../../contacts/messages/base/BaseMsgDataGrid';
import {IEventType} from '../../../dto/EventType.dto';
import {ISeason} from '../../contacts/messages/base/BaseMessagesReducer';
import IconViewButton from '../../../components/Button/IconViewButton';
import CustomModal from '../../../components/modal/CustomModal';
import {ViewStingerMsgShowCmpt} from './ViewStingerMsgShowCmpt';
import IconEditButton from '../../../components/Button/IconEditButton';
import DownloadButton from '../../../components/Button/DownloadButton';

const defaultEventSelectAll = {
  id: -1,
  event_name: 'All Sports',
  event_short_name: 'All Sports',
};

const defaulSeasonSelectAll = {
  id: -1,
  season_name: 'All Seasons',
};

const StingersDataGrid = () => {
  const isGT = useSelector(isGTSelector);
  const isNRSZ = useSelector(isNRorSZSelector);
  const companyId = useSelector(companyIdSelector);
  const [isSeasonForm, setIsSeasonForm] = useState(false);
  const [isStepperOpen, setIsStepperOpen] = useState(false);
  const [status, setStatus] = useState<IEsmStatus>('Active');
  const canUpdateStatus = useUserCanAny(
    'is-admin,partner,stinger-message:update-status,stinger-message:delete,stinger-message:update,stinger-message:show',
  );
  const [selectedEvent, setSelectedEvent] = useState<IEventType>(
    defaultEventSelectAll as IEventType,
  );
  const [selectedSeason, setSelectedSeason] = useState<ISeason>(
    defaulSeasonSelectAll,
  );

  const [query, setQuery] = useState<IStingerMsgQueryParams>({
    search: '',
    page: 0,
    pageSize: 10,
    status: 'Active',
  });

  const {data, isLoading} = useRqStingerMessageList(companyId, query);

  const columns = useMemo(() => {
    const cols = [
      {
        field: 'url',
        type: 'actions',
        headerName: 'Play',
        width: 80,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.url ? (
            <Stack direction='row' spacing={2}>
              <MusicPreviewBtn
                id={params.row.id}
                url={params.row.url}
                track_name={params.row.media_track?.track_name}
                track_artist={params.row.media_track?.track_artist}
              />
              <DownloadButton
                filename={params.row.file_name}
                url={params.row.url}
              />
            </Stack>
          ) : null;
        },
      },
      {...GridDurationColumn, sortable: false},
      {
        field: 'name',
        headerName: 'Message Name',
        hideable: false,
        flex: 2,
        renderCell: (params: GridRenderCellParams) => (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Tooltip title={params.row.message ? params.row.message : 'NA'}>
              <Info fontSize='small' style={{marginRight: 4}} />
            </Tooltip>
            {params.value || 'NA'}
          </div>
        ),
      },
      {
        field: 'schools',
        headerName: 'School(s)',
        hideable: false,
        flex: 2,
        sortable: false,
        filterable: false,
        valueGetter: (params: GridRenderCellParams) =>
          params.row.schools.map((s: ICompanySchool) => s.name).join(', '),
      },
      {
        field: 'stations',
        headerName: 'Station(s)',
        hideable: false,
        flex: 2,
        sortable: false,
        filterable: false,
        valueGetter: (params: GridRenderCellParams) =>
          params.row.stations
            .map((s: IStation) => s.label || s.name)
            .join(', '),
      },
      {
        field: 'events',
        headerName: 'Events(s)',
        hideable: false,
        flex: 2,
        sortable: false,
        filterable: false,
        valueGetter: (params: GridRenderCellParams) =>
          params.row.events.map((e: IEventType) => e.event_name).join(', '),
      },
      {
        field: 'created_at',
        headerName: 'Created Date',
        hideable: false,
        width: 220,
        renderCell: (params: GridRenderCellParams) => {
          return dayjs(params.value).format('MM/DD/YYYY hh:mm:ss A');
        },
      },
      {
        field: 'seasons',
        headerName: 'Season(s)',
        hideable: false,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          return params.value.length > 0
            ? params.value.join(',')
            : params.row.is_all_seasons === 1
            ? 'Year Around'
            : '';
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Action',
        minWidth: 220,
        sortable: false,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => (
          <ActionDetails params={params} status={status} />
        ),
      },
    ];
    if (isGT) {
      return cols.filter(o => o.field !== 'seasons') as GridColDef[];
    }

    return cols.filter(
      o => o.field !== 'schools' && o.field !== 'events',
    ) as GridColDef[];
  }, [isGT, status]);

  const handleSearch = useCallback((val: string) => {
    setQuery(prev => ({
      ...prev,
      search: val,
    }));
  }, []);

  const handlePagination = useCallback((paginate: GridPaginationModel) => {
    setQuery(prev => ({...prev, ...paginate}));
  }, []);

  const handleSortModelChange = (model: GridSortModel) => {
    if (model[0]) {
      setQuery(prev => ({...prev, ...model[0]}));
    }
  };

  const handleStatus = (newValue: IEsmStatus) => {
    setStatus(newValue);
    setQuery(prev => {
      return {
        ...prev,
        status: newValue,
      };
    });
  };

  const closeStepperCreate = useCallback(() => {
    setIsStepperOpen(false);
  }, []);

  const handleEventChange = useCallback((val: IEventType) => {
    setSelectedEvent(val);
    setQuery(prev => {
      if (val.id === -1) {
        const eventRemovedQuery = structuredClone(prev);
        delete eventRemovedQuery.event_id;
        return eventRemovedQuery;
      } else {
        return {...prev, event_id: val.id};
      }
    });
  }, []);

  const handleSeasonChange = useCallback((val: ISeason) => {
    setSelectedSeason(val);
    setQuery(prev => {
      if (val.id === -1) {
        const seasonRemovedQuery = structuredClone(prev);
        delete seasonRemovedQuery.season_id;
        return seasonRemovedQuery;
      } else {
        return {...prev, season_id: val.id};
      }
    });
  }, []);

  return (
    <Box className='GridTableList'>
      <Grid container direction='row' className='h-85 items-center pt-5'>
        <Grid item xs={6} className='flex items-center'>
          <FormControl sx={{width: 200}} className='mr-2'>
            <ActiveDropdown value={status} onChange={handleStatus} />
          </FormControl>
          {isGT && (
            <FormControl sx={{width: 200}} className='mr-2'>
              <EventsFilter
                selectedEvent={selectedEvent as IEventType}
                setSelectedEvent={handleEventChange}
                company_id={companyId}
              />
            </FormControl>
          )}
          {isNRSZ && (
            <FormControl sx={{width: 200}} className='mr-2'>
              <SeasonsFilter
                selectedSeason={selectedSeason as ISeason}
                setSelectedSeason={handleSeasonChange}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item xs={6} className='flex items-center justify-end'>
          <Box className='flex items-center ' style={{gap: '16px'}}>
            {isNRSZ ? (
              <UserCanAny scope='is-admin,season:list'>
                <BaseMessageSeason
                  open={isSeasonForm}
                  setOpen={setIsSeasonForm}
                />
              </UserCanAny>
            ) : null}
            <InputSearch
              className='max-w-218'
              placeholder='Search'
              value={`${query.search}`}
              onChange={handleSearch}
            />
            <UserCanAny scope={`is-admin,stinger-message:create`}>
              <CreateButton
                icon={IconAdd}
                className='create_btn_message'
                onClick={() => setIsStepperOpen(true)}
              />
            </UserCanAny>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{height: 'calc(100vh - 250px)'}}>
        <DataGrid
          disableColumnMenu
          disableColumnSelector
          className='custom-data-grid cmc_new_grid h-654 text-white'
          rows={data?.data ?? []}
          columns={columns}
          loading={isLoading}
          rowCount={data?.meta?.total ?? 0}
          paginationModel={{
            page: query.page,
            pageSize: query.pageSize,
          }}
          pageSizeOptions={[10, 25, 50]}
          paginationMode='server'
          onPaginationModelChange={handlePagination}
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          disableRowSelectionOnClick
          columnVisibilityModel={{
            actions: canUpdateStatus,
          }}
          slots={{
            pagination: DataGridCustPagination,
            noRowsOverlay: () => (
              <CustomNoRowsOverlay
                imageSrc={IconAdd}
                message='No stinger messages available'
              />
            ),
          }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
          }}
        />
      </Box>
      {isStepperOpen && <StingerStepper handleClose={closeStepperCreate} />}
    </Box>
  );
};

export default StingersDataGrid;

const ActionDetails = ({
  params,
  status,
}: {
  params: GridRenderCellParams;
  status: IEsmStatus;
}) => {
  const queryClient = useQueryClient();
  const companyId = useSelector(companyIdSelector);
  const [open, setOpen] = useState(false);
  const [apiState, setApiState] = useState({status: '', error: null});
  const [viewId, setViewId] = useState<number>(0);
  const [editId, setEditId] = useState<number>(0);

  const handleSubmit = useCallback(() => {
    setApiState({
      status: 'pending',
      error: null,
    });
    apiPut(
      `/v1/company/${companyId}/message/stinger-message/${params.row.id}/status`,
    )
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [`v1/company/${companyId}/message/stinger-message`],
          refetchType: 'active',
        });
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  }, [companyId, params.row.id, queryClient]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setApiState({
      status: '',
      error: null,
    });
    setOpen(false);
  };

  const openViewStingerModal = useCallback((id: number) => {
    setViewId(id);
  }, []);

  const closeViewStingerModal = useCallback(() => {
    setViewId(0);
  }, []);

  const openEditStingerModal = useCallback((id: number) => {
    setEditId(id);
  }, []);

  const closeEditStingerModal = useCallback(() => {
    setEditId(0);
  }, []);

  return (
    <Stack direction='row' spacing={1}>
      <UserCanAny scope='is-admin,partner,stinger-message:show'>
        <IconViewButton
          onClick={() => openViewStingerModal(params.row.id)}
          aria-label='view'
        />
      </UserCanAny>
      <UserCanAny scope='is-admin,stinger-message:delete'>
        <DeleteRow
          dialog_content={
            <>
              <Typography>
                This action will delete the following Stinger Request "
                {params.row.name}"
              </Typography>
              <Typography>
                NOTE: The stinger message once deleted will not be retrieved
              </Typography>
            </>
          }
          refreshQueryKey={`v1/company/${companyId}/message/stinger-message`}
          url={`v1/company/${companyId}/message/stinger-message/${params.row.id}`}
        />
      </UserCanAny>
      <UserCanAny scope='is-admin,stinger-message:update'>
        <IconEditButton
          onClick={() => openEditStingerModal(params.row.id)}
          aria-label='edit'
        />
      </UserCanAny>
      {params.row.status !== 'In Progress' ? (
        <UserCanAny scope='is-admin,stinger-message:update-status'>
          {status === 'Inactive' ? (
            <IconButton
              aria-label='restore'
              color='primary'
              title='Restore'
              onClick={handleOpen}>
              <Restore />
            </IconButton>
          ) : (
            <IconButton
              aria-label='deactivate'
              color='error'
              title='Deactivate'
              onClick={handleOpen}>
              <NotInterested />
            </IconButton>
          )}
        </UserCanAny>
      ) : null}
      {apiState.status !== '' && (
        <DialogApiRequestStatus
          apiState={apiState}
          onRetry={handleSubmit}
          onEdit={handleClose}
          onClose={handleClose}
        />
      )}
      {open && (
        <CommonDialog
          open={open}
          status={status === 'Active' ? true : false}
          handleSubmit={handleSubmit}
          handleCancel={handleClose}
        />
      )}
      <CustomModal
        open={viewId !== 0}
        onClose={closeViewStingerModal}
        title='View Stinger Message'
        body={<ViewStingerMsgShowCmpt id={viewId} />}
        actionButtonText='Close Modal'
        onActionButtonClick={closeViewStingerModal}
        maxwidth='740'
        customHeight='482'
      />
      {editId !== 0 && (
        <StingerStepper
          stingerId={editId}
          handleClose={closeEditStingerModal}
        />
      )}
    </Stack>
  );
};
