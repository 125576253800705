import {TextField, TextFieldProps} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import {ChangeEvent} from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    customTextarea: {
      width: '100%',
      '& .MuiInputBase-input': {
        boxSizing: 'border-box',
        lineHeight: '1.5',
        width: '100%',
      },
    },
  }),
);

type CustomTextareaProps = Omit<TextFieldProps, 'onChange' | 'value'> & {
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
};

export const CustomTextarea = ({
  placeholder,
  onChange,
  value,
  ...props
}: CustomTextareaProps) => {
  const classes = useStyles();
  return (
    <TextField
      placeholder={placeholder || 'Enter Message here'}
      multiline
      className={classes.customTextarea}
      onChange={onChange}
      value={value}
      {...props}
      rows={props.rows ?? 4}
    />
  );
};
