import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import TimedMsgRequestContentMessageCard, {
  iTimedMessageMessageItem,
} from './TimedMsgRequestContentMessageCard';
import TimedMsgRequestContentSongCard from './TimedMsgRequestContentSongCard';
import TimedMsgRequestSequenceBuilder from './TimedMsgRequestSequenceBuilder';
import {DialogClose} from '../../../../../components/dialogs/DialogFrame/DialogClose';
import {IStation} from '../../../../../dto/Station.dto';
import MusicPreviewBtn from '../../../../../components/music/MusicPreviewBtn';
import DownloadButton from '../../../../../components/Button/DownloadButton';

type Props = {
  type: string;
  messages: iTimedMessageMessageItem[];
  setMessages: Dispatch<SetStateAction<iTimedMessageMessageItem[]>>;
  setFooterText: Dispatch<SetStateAction<string>>;
  selectedStations: IStation[];
  defaultMessages?: iTimedMessageMessageItem[];
};
const TimedMsgRequestContents = ({
  type,
  messages,
  setMessages,
  setFooterText,
  selectedStations,
  defaultMessages,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [warningDialogId, setWarningDialogId] = useState(0);

  const handleChange = (newMessage: iTimedMessageMessageItem) => {
    setMessages(prevMessages => {
      const newMessages = [...prevMessages];
      const index = newMessages.findIndex(o => o.id === newMessage.id);
      if (index !== -1) {
        if (newMessage.id > 0 && !newMessage.track) {
          setWarningDialogId(newMessage.id);
        } else {
          newMessages.splice(index, 1, newMessage);
        }
      }
      return newMessages;
    });
  };

  const handleUpdateChange = () => {
    setMessages(prev =>
      prev.map(o => {
        if (o.id === warningDialogId) {
          const newMsgId = -1 * Date.now();
          setSelectedItem(newMsgId);
          return {...o, id: newMsgId, isNew: true};
        }
        return o;
      }),
    );
    setWarningDialogId(0);
  };

  const content = useMemo(() => {
    if (type === 'sequence') {
      return messages.filter(o => o.id === selectedItem);
    }
    return messages;
  }, [messages, selectedItem, type]);

  useEffect(() => {
    if (type === 'sequence') {
      setFooterText(`Up to 20 messages/songs can be added for each request`);
    }
    return () => {
      setFooterText('');
    };
  }, [setFooterText, type]);

  return (
    <Grid container spacing={1}>
      <Grid item sm={defaultMessages ? 10 : 12}>
        <Grid container>
          {warningDialogId ? (
            <WarningDialog
              onClose={() => setWarningDialogId(0)}
              handleUpdateChange={handleUpdateChange}
            />
          ) : null}
          {type === 'sequence' && (
            <>
              <Grid item xs={12}>
                <Stack direction='row' className='items-center'>
                  <Typography variant='h5' className='mr-7'>
                    Timed Sequence
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    className='text-white-50 text-14'>
                    A sequence could be a mix of Timed Messages and Songs
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={12}>
                <TimedMsgRequestSequenceBuilder
                  items={messages}
                  onChange={setMessages}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  type={type}
                  handleChange={handleChange}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {(type === 'message' || type === 'song') && (
              <>
                {content.map(message => {
                  if (message.type === 'message') {
                    return (
                      <TimedMsgRequestContentMessageCard
                        key={message.id}
                        message={message}
                        setMessages={setMessages}
                        onChange={handleChange}
                      />
                    );
                  }
                  return (
                    <TimedMsgRequestContentSongCard
                      key={message.id}
                      message={message}
                      onChange={handleChange}
                      selectedStations={selectedStations}
                    />
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {defaultMessages ? (
        <Grid item sm={2}>
          <Typography className='text-20 font-bold' align='center'>
            Existing Data
          </Typography>
          {defaultMessages.map(o => (
            <ExistMessage message={o} key={o.id} />
          ))}
        </Grid>
      ) : null}
    </Grid>
  );
};

const ExistMessage = ({message}: {message: iTimedMessageMessageItem}) => {
  if (message.type === 'message') {
    return (
      <Stack mt={2}>
        <Stack direction='row' spacing={2}>
          <Typography className='text-20 font-bold'>Message</Typography>
          <Box>
            {message.track && (
              <Stack direction='row' spacing={2}>
                <MusicPreviewBtn
                  url={message.track?.url}
                  id={message.track.id}
                  track_name={message.track?.track_name}
                  track_artist={message.track?.track_artist}
                />
                <DownloadButton
                  filename={message.track?.track_name}
                  url={message.track?.url}
                />
              </Stack>
            )}
          </Box>
        </Stack>
        <Typography>{message.message}</Typography>
      </Stack>
    );
  } else if (message.type === 'song') {
    return (
      <Stack mt={2}>
        <Stack direction='row' spacing={2}>
          <Typography className='text-20 font-bold'>Song</Typography>
          <Box>
            {message.track && (
              <Stack direction='row' spacing={2}>
                <MusicPreviewBtn
                  url={message.track?.url}
                  id={message.track.id}
                  track_name={message.track?.track_name}
                  track_artist={message.track?.track_artist}
                />
                <DownloadButton
                  filename={message.track?.track_name}
                  url={message.track?.url}
                />
              </Stack>
            )}
          </Box>
        </Stack>
        <Stack>
          {message.artist && message.title ? (
            <Stack>
              <Typography>
                <b className='italic'>Artist:</b> {message.artist}
              </Typography>
              <Typography>
                <b className='italic'>Title:</b> {message.title}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    );
  }
  return null;
};

export default TimedMsgRequestContents;

const WarningDialog = ({
  onClose,
  handleUpdateChange,
}: {
  onClose: () => void;
  handleUpdateChange: () => void;
}) => {
  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle className='flex justify-between items-center'>
        <Typography className='text-white' align='center'>
          Warning
        </Typography>
        <DialogClose onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        Editing will delete the current request which In-progress. Do you want
        to continue?
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleUpdateChange}>
          Accept
        </Button>
        <Button variant='contained' color='secondary' onClick={onClose}>
          Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
};
