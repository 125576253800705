import {useSelector} from 'react-redux';
import Draggable from 'react-draggable';
import {RootState} from '../../../store/configstore';
import {Slider, Typography} from '@mui/material';
import NOPlayerIcon from '../../../asset/icons/miniplayerDefaultIcon.svg';
import {makeStyles} from '@mui/styles';
import {formatMiniPlayerTime} from '../../../Service/CommonService';
import MiniPlayerControlSections from './MiniPlayerControlSections';
import useMiniPlayer from './useMiniPlayer';
import MarqueeComponent from '../../music/MarqueeComponent';

const miniplayer: React.CSSProperties = {
  width: '300px',
  height: '100px',
  position: 'fixed', // Keep it fixed for proper placement
  right: '110px',
  bottom: '120px',
  zIndex: 99999, // Ensure it's on top of other elements
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export const useMiniPlayerStyles = makeStyles({
  miniPlayer: {
    '& .mini-player-control-panels': {
      background:
        'transparent linear-gradient(180deg, #0056A0 0%, #0C152C 100%) 0% 0% no-repeat padding-box',
      boxShadow: '0px 6px 6px #000000B7',
      borderRadius: '10px',
      opacity: 1,
      height: '55px',
    },
    '& .drag-indicator-pane': {
      justifyContent: 'flex-start',
      marginRight: 'auto',
      padding: '10px',
      flexGrow: 1,
    },
    '& .player-control-pane': {
      justifyContent: 'center',
      flexGrow: 1,
    },
    '& .volume-control-close-panel': {
      display: 'flex',
      justifyContent: 'space-evenly',
      marginLeft: 'auto',
      alignItem: 'center',
      flexGrow: 1,
    },
    '& .play-pause-icon': {
      width: '65px',
      marginTop: '5px',
    },
    '& .diasabled-icon': {
      filter: 'brightness(0.7)',
    },
    '& .volume-slider-container': {
      background: 'white',
      width: '16px',
      height: '80px',
      position: 'absolute',
      bottom: '22px',
      borderRadius: '10px',
    },
    '& .volume-slider': {
      position: 'absolute',
      bottom: '14px',
      left: '-3px',
      height: '60px',
      display: 'block',
    },
    '& .volume-control-close-panel:hover .volume-slider': {
      display: 'block',
    },
    '& .volume-slider input[type="range"]': {
      writingMode: 'bt-lr',
      '-webkit-appearance': 'slider-vertical',
      width: '100px',
      height: '30px',
    },
    '& .speaker-panel': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '8px',
    },
    '& .speaker-panel .voume-slider .MuiSlider-root': {
      color: '#31B221',
      width: '3px',
      padding: '0 1px !important',
    },
    '& .song-track': {
      width: '229px',
      opacity: 1,
      backdropFilter: 'blur(3px)',
      '-webkit-backdrop-filter': 'blur(3px)',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      background: '#5e58587a',
      padding: '15px 10px 0px 10px',
      filter: 'brightness(1.5)',
    },
    '& .player-title': {
      font: 'normal normal bold 13px / 20px Poppins',
      letterSpacing: '0px',
      color: '#FFFFFF',
      opacity: 1,
      whiteSpace: 'nowrap',
      overflowX: 'auto',
    },
    '& .player-artist': {
      textAlign: 'left',
      font: 'normal normal normal 11px / 17px Poppins',
      letterSpacing: '0px',
      color: '#FFFFFF',
      opacity: 1,
      whiteSpace: 'nowrap',
      overflowX: 'auto',
    },
    '& .text-duration': {
      fontSize: '10px',
      paddingBottom: '3px',
    },
  },
});

const MiniPlayer = () => {
  const classes = useMiniPlayerStyles();
  const {songUrl, isVisible, track_name, track_artist} = useSelector(
    (state: RootState) => state.player,
  );

  const {
    isPlaying,
    currentTime,
    duration,
    currentVolume,
    isMuted,
    toggleMute,
    handleVolumeChange,
    togglePlay,
    seekTo,
    handleClose,
  } = useMiniPlayer(songUrl);

  if (!isVisible || !songUrl) return null;
  return (
    <Draggable bounds='parent'>
      <div className={classes.miniPlayer} style={miniplayer}>
        <div className='w-229 player-info flex flex-row justify-between'>
          <div className='flex flex-column items-center'>
            <div className='song-track '>
              <div className='flex flex-row items-center justify-between'>
                <div className='w-full'>
                  <MarqueeComponent
                    text={track_name ?? ''}
                    className='player-title'
                  />
                  <MarqueeComponent
                    text={track_artist ?? ''}
                    className='player-artist'
                  />
                </div>
                <img src={NOPlayerIcon} alt='Song Default Icon' />
              </div>
              <div>
                <div>
                  <Slider
                    value={currentTime}
                    max={duration}
                    onChange={(_, value) => {
                      const newTime = value as number;
                      seekTo(newTime);
                    }}
                    sx={{
                      color: '#00E2FF',
                      padding: '2px 0',
                      '& .MuiSlider-thumb': {
                        width: '11px',
                        height: '11px',
                        border: '1px solid #fff',
                      },
                    }}
                  />
                </div>
                <div className='flex flex-row justify-end'>
                  <Typography className='text-duration'>
                    {formatMiniPlayerTime(currentTime)} /{' '}
                    {formatMiniPlayerTime(duration)}
                  </Typography>
                </div>
              </div>
            </div>
            <MiniPlayerControlSections
              isPlaying={isPlaying}
              isMuted={isMuted}
              currentVolume={currentVolume}
              toggleMute={toggleMute}
              togglePlay={togglePlay}
              handleVolumeChange={handleVolumeChange}
              handleClose={handleClose}
            />
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default MiniPlayer;
