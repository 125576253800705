import {useCallback} from 'react';
import {ButtonProps, IconButton} from '@mui/material';
import IconPlay from '../../asset/icons/icon_play.svg';
import {useDispatch} from 'react-redux';
import {playSong} from '../../store/Slices/playerSlice';

type Props = {
  id: string | number | null;
  url: string;
  size?: boolean;
  track_name: string;
  track_artist: string;
  buttonProps?: ButtonProps;
};

const MusicPreviewBtn = ({
  id,
  url,
  size,
  track_name,
  track_artist,
  buttonProps,
}: Props) => {
  const dispatch = useDispatch();

  const sizeSet = size ? {width: '29px', height: '29px'} : {};
  const togglePlay = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      dispatch(
        playSong({
          songUrl: url,
          track_name: track_name,
          track_artist: track_artist,
          id: Number(id) || 0,
        }),
      );
    },
    [dispatch, url, id, track_name, track_artist],
  );

  return (
    <IconButton
      className='p-0'
      aria-label='play'
      onClick={togglePlay}
      {...buttonProps}
      disabled={url ? url.trim() === '' : true}>
      <img src={IconPlay} alt='' style={sizeSet} />
    </IconButton>
  );
};

export default MusicPreviewBtn;

export const MusicPreviewBtnDisable = () => (
  <IconButton aria-label='play' disabled={true}>
    <img src={IconPlay} alt='' />
  </IconButton>
);
