import {styled, Typography} from '@mui/material';

const MarqueeContainer = styled('div')({
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'start',
});

const MarqueeText = styled(Typography)<{isMarquee: boolean}>(({isMarquee}) => ({
  display: 'inline-block',
  whiteSpace: 'nowrap',
  animation: isMarquee ? 'marquee-right-to-left 10s linear infinite' : 'none',
  animationDelay: isMarquee ? '2s' : '0s',
  font: 'normal normal normal 11px / 17px Poppins',
  '&:root': {
    '@keyframes marquee-right-to-left': {
      '0%': {
        transform: 'translateX(0)',
      },
      '10%': {
        transform: 'translateX(0)',
      },
      '100%': {
        transform: 'translateX(-100%)',
      },
    },
  },
}));

const MarqueeComponent = ({
  text,
  className,
}: {
  text: string;
  className: string;
}) => {
  const isMarquee = text.length > 30;

  return (
    <MarqueeContainer>
      <MarqueeText className={className} isMarquee={isMarquee}>
        {text}
      </MarqueeText>
    </MarqueeContainer>
  );
};

export default MarqueeComponent;
