import {IHubspotContact} from '../../../dto/Hubspot.dto';

export type ContactActions =
  | 'reset'
  | 'initContacts'
  | 'initCompany'
  | 'setCustomName'
  | 'companyCode'
  | '';

export type ContactAction = {
  type: ContactActions;
  payload: {
    contacts?: IHubspotContact[];
    setCustomName?: boolean;
    companyCode?: string;
  };
};

export type ContactState = {
  contacts: IHubspotContact[];
  setCustomName: boolean;
  companyCode: string;
};

export const initialOnboardContact: ContactState = {
  contacts: [],
  setCustomName: false,
  companyCode: '',
};

const PartnerContactReducer = (
  state: ContactState,
  action: ContactAction,
): ContactState => {
  const {type, payload} = action;
  switch (type) {
    case 'reset':
      return initialOnboardContact;
    case 'setCustomName':
      return {
        ...state,
        setCustomName: payload?.setCustomName ?? state.setCustomName,
      };
    case 'companyCode':
      let companyCode = payload?.companyCode ?? state.companyCode;
      companyCode = companyCode.slice(0, 20);
      return {
        ...state,
        companyCode: companyCode,
      };
    case 'initContacts':
      return {
        ...state,
        contacts: payload.contacts ?? state.contacts,
      };
    default:
      return state;
  }
};

export type ContactParms = {
  dispatch: React.Dispatch<ContactAction>;
  state: ContactState;
};

export default PartnerContactReducer;
