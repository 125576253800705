import {useCallback, useState} from 'react';
import {Slider} from '@mui/material';

import DragIndicatorIcon from '../../../asset/icons/8dots.svg';
import PrevSong from '../../../asset/icons/prevSong.svg';
import NextSong from '../../../asset/icons/nextSong.svg';
import playIcon from '../../../asset/icons/PlayIcon.svg';
import pauseIcon from '../../../asset/icons/pauseIcon.svg';
import closeIcon from '../../../asset/icons/playerClose.svg';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

type Props = {
  isPlaying: boolean;
  isMuted: boolean;
  currentVolume: number;
  toggleMute: () => void;
  togglePlay: () => void;
  handleVolumeChange: (_: Event, value: number | number[]) => void;
  handleClose: () => void;
};
const MiniPlayerControlSections = ({
  isPlaying,
  isMuted,
  currentVolume,
  toggleMute,
  togglePlay,
  handleVolumeChange,
  handleClose,
}: Props) => {
  const [showSlider, setShowSlider] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setShowSlider(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setTimeout(() => {
      setShowSlider(false);
    }, 100);
  }, []);
  return (
    <div className='flex flex-row items-center mini-player-control-panels w-267'>
      <div className='flex flex-row items-center drag-indicator-pane'>
        <img
          src={DragIndicatorIcon}
          alt='Drag Icon'
          className='cursor-pointer'
        />
      </div>
      <div className='flex flex-row items-center player-control-pane'>
        <img
          src={PrevSong}
          alt='Previous Song Icon'
          className='diasabled-icon'
        />
        <img
          src={isPlaying ? pauseIcon : playIcon}
          alt='Pause Play Song Icon'
          onClick={togglePlay}
          className='play-pause-icon cursor-pointer'
        />
        <img src={NextSong} alt='Next Song Icon' className='diasabled-icon' />
      </div>
      <div className='volume-control-close-panel w-25'>
        <div
          className='speaker-panel pt-2 w-p-50 '
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          {isMuted ? (
            <VolumeOffIcon onClick={toggleMute} />
          ) : currentVolume < 0.4 ? (
            <VolumeMuteIcon onClick={toggleMute} />
          ) : currentVolume < 0.8 ? (
            <VolumeDownIcon onClick={toggleMute} />
          ) : (
            <VolumeUpIcon onClick={toggleMute} />
          )}

          {showSlider && (
            <div className='volume-slider-container'>
              <div className='volume-slider' onMouseLeave={handleMouseLeave}>
                <Slider
                  orientation='vertical'
                  size='small'
                  color='success'
                  value={currentVolume}
                  max={1.0}
                  step={0.1}
                  onChange={handleVolumeChange}
                  onMouseDown={e => {
                    e.stopPropagation();
                  }}
                  onTouchStart={e => {
                    e.stopPropagation();
                  }}
                  sx={{
                    background: 'white',
                    width: '4px',
                    padding: '0 5px',
                    margin: '4px',
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className='w-p-50 flex justify-center items-center'>
          <img
            src={closeIcon}
            alt='Close Icon'
            className='cursor-pointer'
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
};

export default MiniPlayerControlSections;
