import {
  Box,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {IStation} from '../../../../../dto/Station.dto';
import {iTimedMsgTimesItem} from '../times/TimedMsgRequestTimesCard';
import {iTimedMessageMessageItem} from '../contents/TimedMsgRequestContentMessageCard';
import {ContentItemTrack} from './TimedMsgRequestReviewContents';
import TimedMsgRequestReviewDates from './TimedMsgRequestReviewDates';
import TimedMsgRequestReviewDayTimes from './TimedMsgRequestReviewDayTimes';
import {ITimedMessageDayType} from '../../../../../dto/timed-message/TimedMessageRequest.dto';
import {iTimedMsgDateRange} from '../TimedMessageRequest';

type Props = {
  stations: IStation[];
  messageName: string;
  messages: iTimedMessageMessageItem[];
  dayType: ITimedMessageDayType;
  dateRanges: iTimedMsgDateRange[];
  daysTimes: iTimedMsgTimesItem[];
  from?: string;
  restoreId?: number;
};

const TimedMsgRequestReview = ({
  stations,
  messageName,
  messages,
  dayType,
  dateRanges,
  daysTimes,
  from,
  restoreId,
}: Props) => {
  return (
    <>
      {from !== 'view' && (
        <Typography variant='h5' className='mb-5'>
          Review
        </Typography>
      )}
      <TableContainer>
        <Table>
          <TableBody>
            {restoreId ? null : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className='text-white-50 w-200 mt-2 text-16'>
                  <Box className='flex'>
                    <Box className='mb-4 w-250'>{`Station${
                      stations.length > 1 ? 's' : ''
                    }`}</Box>
                    <Box className='mb-4 text-white'>
                      <List disablePadding>
                        {stations.map(station => (
                          <ListItem key={station.id} className='p-0'>
                            <ListItemText id={`station-list-${station.id}`}>
                              {station.label || station.name}
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Box>

                  <Box className='flex'>
                    <Box className='mb-6 w-250'>Message Name</Box>
                    <Box className='text-white' id='message-name'>
                      {messageName}
                    </Box>
                  </Box>

                  <Box className='mb-4 text-white max-h-300px overflow-auto scrollbar'>
                    <Typography variant='body2' className='mb-4'>
                      Messages/Song Details
                    </Typography>

                    {messages.map(item => (
                      <TimedMessageDetails key={item.id} message={item} />
                    ))}
                  </Box>
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell className='text-white-50 w-250 text-16'>
                Date(s)
                <Typography className='mt-3'>
                  {dayType === 'no-end'
                    ? 'No End Date'
                    : dayType === 'one-day'
                    ? 'One Day'
                    : 'Multiple Days'}
                </Typography>
              </TableCell>
              <TableCell className='text-16' id='date'>
                <TimedMsgRequestReviewDates
                  type={dayType}
                  ranges={dateRanges}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='text-white-50 text-16'>
                Day & Time(s)
              </TableCell>
              <TableCell>
                <TimedMsgRequestReviewDayTimes items={daysTimes} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default TimedMsgRequestReview;

export function TimedMessageDetails({
  message,
}: {
  message: iTimedMessageMessageItem;
}) {
  return (
    <Box className='flex mb-6'>
      <Box className='w-250 text-white-50'>{message.label}</Box>
      <Box className='text-white'>
        {message.track ? (
          <ContentItemTrack track={message.track} id={message.id} />
        ) : message.type === 'song' && message.artist ? (
          <Box className='flex gap-2'>
            <Typography variant='body2' className='text-white-50'>
              Artist:
              <Typography
                component='span'
                variant='subtitle2'
                className='text-white'>
                {message.artist}
              </Typography>
            </Typography>
            <Typography variant='body2' className='text-white-50'>
              Title:
              <Typography
                component='span'
                variant='subtitle2'
                className='text-white'>
                {message.title}
              </Typography>
            </Typography>
          </Box>
        ) : (
          <Typography variant='body2' className='w-425'>
            <pre>{message.message}</pre>
          </Typography>
        )}
      </Box>
    </Box>
  );
}
