import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';
import {ICompany, IProduct, IProductLevel} from '../../dto/Company.dto';
import {ICompanySchool} from '../../dto/CompanySchool.dto';
import {RootState} from '../configstore';

export interface IOnboardCmySchools extends ICompany {
  hubspot_id: number;
  product: IProduct | null;
  product_level: IProductLevel | null;
  discount: number;
  branding_type: 1 | 2 | null; //1-DistrictBranding , 2-SchoolBranding
  schools: ICompanySchool[];
}

const initialState: IOnboardCmySchools = {
  id: 0,
  name: '',
  hubspot_id: 0,
  branding_type: null,
  product: null,
  product_level: null,
  discount: 0,
  schools: [],
};
export const OnboardCmySchoolsSlice = createSlice({
  name: 'onboardCmySchools',
  initialState,
  reducers: {
    init: (
      state: IOnboardCmySchools,
      action: PayloadAction<IOnboardCmySchools>,
    ) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.hubspot_id = action.payload.hubspot_id;
      state.branding_type = action.payload.branding_type;
      state.product = action.payload.product;
      state.product_level = action.payload.product_level;
      state.discount = action.payload.discount;
      state.schools = action.payload.schools;
    },
  },
});

export const {init} = OnboardCmySchoolsSlice.actions;

export default OnboardCmySchoolsSlice.reducer;

const companySelector = (state: RootState) => state.onboardCmpSchools;

export const taskCompanyProduct = createSelector(
  companySelector,
  state => state.product,
);

export const taskCompanyProductLevel = createSelector(
  companySelector,
  state => state.product_level,
);
