import React from 'react';
import {Outlet} from 'react-router-dom';
import MiniPlayer from './MiniPlayer/MiniPlayer';
import {BackDropCmpt} from './AuthUsers';

export default function MiniPlayerBaseRoute() {
  return (
    <React.Suspense fallback={<BackDropCmpt />}>
      <Outlet />
      <MiniPlayer />
    </React.Suspense>
  );
}
