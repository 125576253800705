import dayjs from 'dayjs';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {
  companyIdSelector,
  isGTSelector,
} from '../../../store/Slices/companySlice';
import {
  IGetStingerMessageRequestResponse,
  useRqViewStingerListMessagesByRequestID,
} from '../../../react_query/messages/message-requests/StingerMessageList';
import MusicPreviewBtn from '../../../components/music/MusicPreviewBtn';
import {RequestTheme} from '../../../components/layouts/Theme';
import {useStoreSelector} from '../../../store/configstore';
import DownloadButton from '../../../components/Button/DownloadButton';

export const ViewStingerMsgShowCmpt = ({id}: {id: number}) => {
  const companyId = useStoreSelector(companyIdSelector);
  const isGT = useSelector(isGTSelector);

  const [content, setContent] = useState<IGetStingerMessageRequestResponse>();

  const {data, isFetched, isError} = useRqViewStingerListMessagesByRequestID(
    companyId,
    id,
    Boolean(id),
  );
  useEffect(() => {
    if (isFetched && !isError && data) {
      setContent(data);
    }
  }, [data, isError, isFetched]);

  return (
    <ThemeProvider theme={RequestTheme}>
      <Container className='p-0'>
        <TableContainer className='bg-transparent'>
          <Table>
            <TableBody>
              <>
                {isGT && (
                  <TableRow>
                    <TableCell width={200}>School(s)</TableCell>
                    <TableCell>
                      {content?.schools?.map(val => val.name).join(', ')}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell width={200}>Station(s)</TableCell>
                  <TableCell>
                    {content?.stations
                      ?.map(val => val.label || val.name)
                      .join(', ')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={200}>Message Name</TableCell>
                  <TableCell>{content?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={200}>Content</TableCell>
                  <TableCell>
                    <pre>{content?.message?.message}</pre>
                  </TableCell>
                </TableRow>
                {content?.events && content?.events?.length ? (
                  <TableRow>
                    <TableCell width={200}>Sports / Events</TableCell>
                    <TableCell>
                      {content?.events
                        .map(event => event.event_name)
                        .join(', ')}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell width={200}>Season(s)</TableCell>
                    <TableCell>
                      {content?.is_all_seasons === 1
                        ? 'Year Around'
                        : content?.seasons
                            ?.map(season => season.season_name)
                            .join(', ')}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell width={200}>Created Date</TableCell>
                  <TableCell>
                    {dayjs(content?.created_at).format('MM/DD/YYYY hh:mm:ss A')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width={200}>Created By</TableCell>
                  <TableCell>{content?.created_by?.name}</TableCell>
                </TableRow>
                {content?.message?.media_track ? (
                  <TableRow>
                    <TableCell width={200}>File</TableCell>
                    <TableCell>
                      {content?.message.media_track ? (
                        <Stack spacing={1} direction='row' alignItems='center'>
                          <MusicPreviewBtn
                            id={content?.message.media_track.id}
                            url={content?.message.media_track.url}
                            track_name={
                              content?.message?.media_track?.track_name
                            }
                            track_artist={
                              content?.message?.media_track?.track_artist
                            }
                          />
                          <DownloadButton
                            filename={content?.message.media_track.track_name}
                            url={content?.message.media_track.url}
                          />
                          <Typography>
                            {content?.message.media_track?.track_name}
                          </Typography>
                        </Stack>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </ThemeProvider>
  );
};
