import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
  ThemeProvider,
  Container,
  Stack,
} from '@mui/material';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {
  companyIdSelector,
  isGTSelector,
} from '../../../../store/Slices/companySlice';
import {useStoreSelector} from '../../../../store/configstore';
import {
  IGetBaseMessageRequestResponace,
  queryKeyMessageRequestList,
  useRqViewBaseListMessagesByRequestID,
} from '../../../../react_query/messages/MessagesByCmpId';
import MusicPreviewBtn from '../../../../components/music/MusicPreviewBtn';
import {RequestTheme} from '../../../../components/layouts/Theme';
import {UserCanAny} from '../../../../components/UserCan';
import DialogApiRequestStatus from '../../../../components/dialogs/DialogApiRequestStatus';
import CommonDialog from '../../../../components/CommonDialog';
import {apiPatch} from '../../../../axiosConfig';
import {useSelector} from 'react-redux';
import DownloadButton from '../../../../components/Button/DownloadButton';

const ViewBaseMsgList = ({id}: {id: number}) => {
  const queryClient = useQueryClient();
  const companyId = useStoreSelector(companyIdSelector);
  const isGT = useSelector(isGTSelector);

  const [content, setContent] = useState<IGetBaseMessageRequestResponace>();
  const [voiceArtist, setVoiceArtist] = useState<string[]>([]);
  const [apiState, setApiState] = useState({status: '', error: null});
  const [open, setOpen] = useState(false);

  const {data, isFetched, isError} = useRqViewBaseListMessagesByRequestID(
    companyId,
    id,
    Boolean(id),
  );
  useEffect(() => {
    if (isFetched && !isError && data) {
      const result = data;
      setContent(data);
      setVoiceArtist(result?.voice_artists ?? []);
    }
  }, [data, isError, isFetched]);
  const handleStatus = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setApiState({
      status: '',
      error: null,
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    setApiState({
      status: '',
      error: null,
    });
  };

  const handleSubmit = () => {
    const id = content?.id;
    setApiState({
      status: 'pending',
      error: null,
    });
    const payload = {
      status: content?.status === 'Active' ? '0' : '1', //If status already in active we have to switch it's state
    };
    apiPatch(`/v1/company/${companyId}/message/base-message/${id}`, payload)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [queryKeyMessageRequestList(companyId, 'base-message')],
          refetchType: 'active',
        });
        setApiState({
          status: 'success',
          error: null,
        });
      })
      .catch(error => {
        setApiState({
          status: 'error',
          error,
        });
      });
  };

  return (
    <>
      {content && content?.status !== 'In Progress' && (
        <UserCanAny scope='is-admin,base-message:update'>
          <Box className='modal-deactive-btn'>
            <Button
              onClick={handleStatus}
              sx={{mt: 2}}
              variant='outlined'
              color={content?.status === 'Inactive' ? 'success' : 'error'}>
              {content?.status === 'Inactive' ? 'Restore' : 'Deactivate'}
            </Button>
          </Box>
        </UserCanAny>
      )}
      <ThemeProvider theme={RequestTheme}>
        <Container className='p-0'>
          <TableContainer className='bg-transparent'>
            <Table>
              <TableBody>
                <>
                  {voiceArtist && !isGT && (
                    <TableRow>
                      <TableCell sx={{width: '200px'}}>Voice(s)</TableCell>
                      <TableCell>{voiceArtist.join(', ')}</TableCell>
                    </TableRow>
                  )}
                  {isGT && (
                    <TableRow>
                      <TableCell>School(s)</TableCell>
                      <TableCell>
                        {content?.schools?.map(val => val.name).join(', ')}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>Station(s)</TableCell>
                    <TableCell>
                      {content?.stations
                        ?.map(val => val.label || val.name)
                        .join(', ')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Message Name</TableCell>
                    <TableCell>{content?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Content</TableCell>
                    <TableCell>
                      <pre>{content?.message?.message}</pre>
                    </TableCell>
                  </TableRow>
                  {content?.events && content?.events?.length ? (
                    <TableRow>
                      <TableCell>Sports / Events</TableCell>
                      <TableCell>
                        {content?.events
                          .map(event => event.event_name)
                          .join(', ')}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>Season(s)</TableCell>
                      <TableCell>
                        {content?.is_all_seasons === 1
                          ? 'Year Around'
                          : content?.seasons
                              ?.map(season => season.season_name)
                              .join(', ')}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>Created Date</TableCell>
                    <TableCell>
                      {dayjs(content?.created_at).format(
                        'MM/DD/YYYY hh:mm:ss A',
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Created By</TableCell>
                    <TableCell>{content?.created_by}</TableCell>
                  </TableRow>
                  {content?.message?.media_track ? (
                    <TableRow>
                      <TableCell>File</TableCell>
                      <TableCell>
                        {content?.message.media_track ? (
                          <Stack
                            spacing={1}
                            direction='row'
                            alignItems='center'>
                            <MusicPreviewBtn
                              id={content?.message.media_track.id}
                              url={content?.message.media_track.url}
                              track_name={
                                content?.message.media_track?.track_name
                              }
                              track_artist={
                                content?.message.media_track?.track_artist
                              }
                            />
                            <DownloadButton
                              filename={
                                content?.message.media_track?.track_name
                              }
                              url={content?.message.media_track.url}
                            />
                            <Typography>
                              {content?.message.media_track?.track_name}
                            </Typography>
                          </Stack>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              </TableBody>
            </Table>
          </TableContainer>

          {apiState.status !== '' && (
            <DialogApiRequestStatus
              apiState={apiState}
              onRetry={handleSubmit}
              onEdit={handleEdit}
              onClose={handleClose}
            />
          )}
          <CommonDialog
            open={open}
            status={content?.status === 'Inactive' ? false : true}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
          />
        </Container>
      </ThemeProvider>
    </>
  );
};

export default ViewBaseMsgList;
