import {lighten} from '@mui/material';
import {makeStyles} from '@mui/styles';

export const colorStyles = makeStyles(({palette, ...theme}) => ({
  '@global': {
    ':root': {
      '--blue-variant1': '#0087C4',
      '--blue-variant2': '#22314D',
      '--blue-variant3': '#2E3957',
      '--blue-variant4': '#1B2338',
      '--blue-variant5': '#1B2841',
      '--blue-variant6': '#0E162B',
      '--blue-variant7': '#1E3262',
      '--blue-variant8': '#121621',
      '--blue-variant9': '#2F3852',
      '--blue-variant10': '#0F1526',
      '--blue-variant11': '#00B0FF',
      '--blue-variant12': '#212738',
      '--blue-variant13': '#242D46',
      '--blue-variant14': '#008FC8',
      '--blue-variant15': '#121620',
      '--blue-variant16': '#252A37',
      '--blue-variant17': '#131929',
      '--blue-variant18': '#1b2e3e',
      '--blue-variant19': '#111727',
      '--blue-variant20': '#3B7ABC',
      '--blue-variant21': '#5CABFF',
      '--blue-variant22': '#27354a',
      '--blue-variant23': '#0454A3',
      '--blue-variant24': '#3B9FFF',
      '--blue-variant25': '#162C5C',
      '--blue-variant26': '#5485FC',
      '--blue_variant27': '#161C2E',
      '--blue_variant28': '#141928',
      '--blue_variant29': '#03061C',
      '--blue_variant30': '#89D6FF',
      '--blue_variant31': '#2d447f',
      '--blue_variant32': '#061229',
      '--blue_variant33': '#2DAEFF',
      '--blue_variant34': '#0E1F4B',
      '--blue_variant35': '#0BA9F0',
      '--blue_variant36': '#182138',
      '--blue_variant37': '#172138',
      '--blue_variant38': '#7D7D7D',
      '--blue_variant39': '#D3D3D3',
      '--blue_variant40': '#0BBDFF',
      '--blue_variant41': '#080C17',
      '--blue-variant42': '#323f4c',
      '--blue-variant43': '#add4ff',
      '--blue-variant44': '#3498db',
      '--blue-variant45': '#2196F3',
      '--blue_variant46': '#03050a',
      '--blue_variant47': '#0c2634',
      '--blue_variant48': '#334256',
      '--blue_variant49': '#1B2338',
      '--blue_variant50': '#081e33',
      '--blue_variant51': '#18284b',
      '--blue_variant52': '#03B0FF',
      '--blue_variant53': '#040810',

      '--green-varaint1': '#198D84',
      '--green-variant2': '#0B2426',
      '--green-variant3': '#264346',
      '--green-variant4': '#06181A',
      '--green-variant5': '#004146',
      '--green-variant6': '#293130',
      '--green-variant7': '#479641',
      '--green-variant8': '#123434',
      '--green-variant9': '#4EA851',
      '--green-variant10': '#e2f0d9',
      '--green-variant11': '#c5e0b4',
      '--green-variant12': '#2F3D41',
      '--green-variant13': '#1A3B45',
      '--green-variant14': '#154C19',
      '--green-variant15': '#57912E',
      '--green-variant16': '#0B9F19',
      '--green-variant17': '#132921',
      '--green-variant18': '#3d6a29',
      '--green-variant19': '#126A14',

      '--yellow-variant1': '#AD933B',
      '--yellow-variant2': '#201D04',
      '--yellow-variant3': '#403C24',
      '--yellow-variant4': '#211E09',
      '--yellow-variant5': '#443C08',
      '--yellow-variant6': '#FFF038',
      '--yellow-variant7': '#EFC127',

      '--text-white-75': '#ffffffb0',
      '--text-white-50': '#ffffff80',
      '--text-white-30': '#ffffff4d',
      '--text-white': '#ffffff',

      '--red-variant1': '#ff6a6a',
      '--red-variant2': '#bf0202',
      '--red-variant3': '#f44336',

      '--gray-variant1': '#BCBCBC',
      '--gray-variant2': '#4D5A71',
      '--gray-variant3': '#5D5D5D',
      '--gray-variant4': '#4B4B4B',
      '--gray-variant5': '#9D9D9D',
      '--gray-variant6': '#A0A0A0',
      '--gray-variant7': '#888888',
      '--gray-variant8': '#7E7E7E',
      '--gray-variant9': '#707070',
      '--gray-variant10': '#DEDEDE',
      '--gray-variant11': '#A2A2A2',
      '--gray-variant12': '#d9d9d9',
      '--gray-variant13': '#6E7895',
      '--gray-variant14': '#E2E2E2',
      '--gray-variant15': '#818181',
      '--gray-varaint16': '#41444C',
      '--gray-varaint17': '#6e6e6e',
      '--gray-varaint18': '#080c20',
      '--gray-varaint19': '#222434',

      '--brown-variant1': '#9D7545',
      '--brown-variant2': '#312E27',

      '--red_variant1': '#461212',
      '--red_variant2': '#DD0000',
      '--red_variant3': '#15384C',
      '--red_variant4': '#960808',

      '--gray-opacity-variant1': '#00000029',
      '--gray-opacity-variant2': '#70707033',
      '--gray-opacity-variant3': '#BABABA88',
      '--gray-opacity-variant4': '#70707088',

      '--blue-opacity-variant1': '#546f8675',
      '--black-opacity-variant1': '#00000080',

      '--bg-danger': '#D72332',
      '--orange-variant1': '#DB4949',
      '--orange-variant2': '#f3db93',
      '--orange-variant3': '#f1c43d',
      '--orange-variant4': '#D75C36',
      '--black': '#000000',
      '--black-variant1': '#080C17',
    },
    '.blue-variant4': {background: `var(--blue-variant4) !important`},
    body: {backgorund: `var(--blue-variant4) !important`},
    '.bg-secondary': {background: `${palette.secondary.main} !important`},
    '.blue-variant2': {background: `var(--blue-variant2) !important`},
    '.blue-variant7': {background: `var(--blue-variant7) !important`},
    '.blue-variant8': {background: `var(--blue-variant8) !important`},
    '.blue-variant9': {background: `var(--blue-variant9)`},
    '.blue-variant15': {background: `var(--blue-variant15) !important`},
    '.blue-variant16': {background: `var(--blue-variant16) !important`},
    '.blue-variant17': {background: `var(--blue-variant17) !important`},
    '.blue-variant18': {background: `var(--blue-variant18) !important`},
    '.blue-variant23': {background: `var(--blue-variant23) !important`},
    '.blue-variant25': {background: `var(--blue-variant25) !important`},
    '.blue_variant27': {background: `var(--blue_variant27) !important`},
    '.blue-variant29': {background: `var(--blue_variant29) !important`},
    '.blue-variant48': {background: `var(--blue_variant48) !important`},
    '.blue-variant49': {background: `var(--blue_variant49) !important`},
    '.blue-variant50': {background: `var(--blue_variant50) !important`},
    '.bg-blue-variant53': {background: `var(--blue_variant53) !important`},
    '.text-blue-variant24': {color: `var(--blue-variant24) !important`},
    '.text-blue-variant1': {color: `var(--blue-variant1) !important`},
    '.bg-green-variant6': {background: `var(--green-variant6)`},
    '.text-green-variant7': {color: `var(--green-variant7)`},
    '.text-gray-variant1': {color: `var(--gray-variant1) !important`},
    '.text-gray-variant5': {color: `var(--gray-variant5)`},
    '.text-gray-variant6': {color: `var(--gray-variant6)`},
    '.text-gray-variant7': {color: `var(--gray-variant7)`},
    '.text-gray-variant11': {color: `var(--gray-variant11) !important`},
    '.blue-opacity-variant1': {background: `var(--blue-opacity-variant1)`},
    '.black-opacity-variant1': {background: `var(--black-opacity-variant1)`},
    '.brown-variant1': {background: `var(--brown-variant1)`},
    '.yellow-border': {border: `1px solid var(--yellow-variant1)`},
    '.black-variant1': {background: `var(--black-variant1)`},
    '.tasklist-background-blue.Mui-selected, .tasklist-background-blue.MuiGrid-item':
      {
        background: `var(--blue-variant2) !important`,
      },
    '.tasklist-background-green.Mui-selected, .tasklist-background-green.MuiGrid-item':
      {
        background: `var(--green-variant2) !important`,
      },
    '.tasklist-background-yellow.Mui-selected, .tasklist-background-yellow.MuiGrid-item':
      {
        background: `var(--yellow-variant2) !important`,
      },
    // '.search-bg': {background: `var(--blue-variant6) !important`},

    //tab border & task list border color
    '.task-border-blue .MuiTabs-indicator,.task-border-blue .Mui-selected': {
      background: `var(--blue-variant1)`,
      borderColor: `var(--blue-variant1)`,
    },
    '.task-border-green .MuiTabs-indicator,.task-border-green .Mui-selected': {
      background: `var(--green-varaint1)`,
      borderColor: `var(--green-varaint1)`,
    },
    '.task-border-yellow .MuiTabs-indicator,.task-border-yellow .Mui-selected':
      {
        background: `var(--yellow-variant1)`,
        borderColor: `var(--yellow-variant1)`,
      },

    //Bg task list card
    '.bg-lightblue-card': {
      background: `var(--blue-variant3) !important`,
      borderColor: `var(--blue-variant1) !important`,
      '&:hover': {background: `var(--blue-variant5) !important`},
    },
    '.bg-lightgreen-card': {
      background: `var(--green-variant3) !important`,
      borderColor: `var(--green-varaint1) !important`,
      '&:hover': {background: `var(--green-variant5) !important`},
    },
    '.bg-lightyellow-card': {
      background: `var(--yellow-variant3) !important`,
      borderColor: `var(--yellow-variant1) !important`,
      '&:hover': {background: `var(--yellow-variant5) !important`},
    },

    // Search background
    'body .task-search-background-blue': {
      background: `var(--blue-variant4) !important`,
    },
    'body .task-search-background-green': {
      background: `var(--green-variant4) !important`,
    },
    '.task-search-background-yellow': {
      background: `var(--yellow-variant4) !important`,
    },
    '.text-white-50': {color: `var(--text-white-50) !important`},
    '.tab-border-lightblue': {background: `var(--blue-variant1)`},
    '.tab-border-lightgreen': {background: `var(--blue-variant1)`},
    '.tab-border-lightyellow': {background: `var(--yellow-variant1)`},
    '.bg-error': {
      background: `${palette.error.main} !important`,
      color: 'white !important',
    },
    '.bg-default': {
      background: `${palette.background.default} !important`,
    },
    '.bg-paper': {background: `${palette.background.paper}`},
    '.bg-gray': {background: 'rgba(0, 0, 0, 0.15) !important'},
    '.bg-light-gray': {background: 'rgba(0, 0, 0, 0.01) !important'},
    '.bg-dark': {background: '#000000 !important', color: '#fff'},
    '.bg-light-dark': {background: '#212121', color: 'white'},
    '.hover-bg-primary': {transition: 'all 250ms'},
    '.hover-bg-primary:hover': {
      background: `${palette.primary.main} !important`,
      color: '#ffffff',
      backgroundColor: `${palette.primary.main} !important`,
      fallbacks: [{color: 'white !important'}],
    },
    '.hover-bg-primary:hover [class^="MuiSvgIcon-"]': {
      fill: 'white !important',
    },
    '.hover-bg-secondary': {transition: 'all 250ms'},
    '.hover-bg-secondary:hover': {
      background: `${palette.secondary.main} !important`,
      color: '#ffffff',
      backgroundColor: `${palette.secondary.main} !important`,
      fallbacks: [{color: 'white !important'}],
    },
    '.hover-bg-error': {transition: 'all 250ms'},
    '.hover-bg-error:hover': {
      background: `${palette.error.main} !important`,
      color: '#ffffff',
      backgroundColor: `${palette.error.main} !important`,
      fallbacks: [{color: 'white !important'}],
    },
    '.hover-bg-green': {transition: 'all 250ms'},
    '.hover-bg-green:hover': {
      background: `rgba(9, 182, 109, 1) !important`,
      color: '#ffffff',
      backgroundColor: `rgba(9, 182, 109, 1) !important`,
      fallbacks: [{color: 'white !important'}],
    },
    '.bg-light-primary': {
      background: `rgba(var(--primary), 0.15) !important`,
    },
    '.bg-light-secondary': {
      background: `${lighten(palette.secondary.main, 0.85)} !important`,
    },
    '.bg-light-error': {
      background: `${lighten(palette.error.main, 0.85)} !important`,
    },
    '.section-bg-light-primary': {background: 'rgba(var(--primary),0.1)'},
    '.bg-light-green': {background: 'rgba(9, 182, 109, 0.15) !important'},
    '.bg-transparent': {background: 'transparent !important'},
    '.text-white': {color: 'rgba(255, 255, 255, 1) !important'},
    '.text-black': {color: 'rgba(0, 0, 0, 0.87) !important'},
    '.text-body': {color: 'rgba(var(--body), 0.87) !important'},
    '.text-white-secondary': {
      color: 'rgba(255, 255, 255, 0.87) !important',
    },
    '.text-muted-white': {color: 'rgba(255, 255, 255, 0.54) !important'},
    '.text-light-white': {color: 'rgba(255, 255, 255, 0.54) !important'},
    '.text-muted': {color: `${palette.text.secondary} !important`},
    '.text-hint': {color: `${palette.text.hint} !important`},
    '.text-gray': {color: 'rgba(0, 0, 0, 0.74) !important'},
    '.text-brand': {color: `${palette.primary.main} !important`},
    '.text-blue-variant21': {color: `var(--blue-variant21)`},
    '.text-secondary': {color: `${palette.secondary.main} !important`},
    '.text-error': {color: `${palette.error.main} !important`},
    '.text-inherit': {color: 'inherit !important'},
    '.gray-on-hover': {transition: 'background 250ms ease'},
    '.gray-on-hover:hover': {background: 'rgba(0, 0, 0, 0.054)'},
    '.border-color-primary': {
      borderColor: `${palette.primary.main} !important`,
    },
    '.border-color-default': {
      borderColor: `${palette.background.default} !important`,
    },
    '.border-color-paper': {
      borderColor: `${palette.background.paper} !important`,
    },
    '.border-green-variant18': {
      border: '1px solid var(--green-variant19) !important',
    },
    '.border-bottom-1': {borderBottom: `1px solid var(--gray-variant2)`},
    '.border-bottom-2': {borderBottom: `1px dashed var(--gray-variant2)`},
    '.border-right-1': {
      borderRight: `1px solid var(--gray-variant2) !important`,
    },
  },
}));
