import {IService} from '../../../dto/Service.dto';

type Discount = {
  percentage: number;
  cost: number;
};

export type ISchool = {
  name: string;
  boys_mascot: string;
  girls_mascot: string;
  facilities: string[];
};

export type IProduct = {
  primary?: number;
  secondary?: number;
  level?: number;
};

export type PartnerState = {
  company_name: string;
  product: IProduct;
  branding_type: 1 | 2 | null; //1-district, 2-school
  level: IService;
  level0: IService;
  level1: IService;
  level2: IService;
  level3: IService;
  discount: Discount;
  full_station: IService;
  odo_station: IService;
  mirror_station: IService;
  laptop: IService;
  setup: IService;
  note: string;
  company_facilities: string[];
  company_schools: ISchool[];
};

export type PartnerActions =
  | 'reset'
  | 'add_primary_product'
  | 'update_product'
  | 'update_company_name'
  | 'district_branding'
  | 'school_branding'
  | 'Level0_init'
  | 'Level0-'
  | 'Level0+'
  | 'Level1_init'
  | 'Level1-'
  | 'Level1+'
  | 'Level2_init'
  | 'Level2-'
  | 'Level2+'
  | 'Level3_init'
  | 'Level3-'
  | 'Level3+'
  | 'full_station_init'
  | 'full_station+'
  | 'full_station-'
  | 'odo_station_init'
  | 'odo_station+'
  | 'odo_station-'
  | 'mirror_station_init'
  | 'mirror_station+'
  | 'mirror_station-'
  | 'discount'
  | 'laptop_init'
  | 'laptop+'
  | 'laptop-'
  | 'setup_init'
  | 'setup+'
  | 'setup-'
  | 'facility+'
  | 'facility-'
  | 'school+'
  | 'school-'
  | 'notes'
  | 'edit_level_price'
  | '';

export type PartnerAction = {
  type: PartnerActions;
  payload: IPayload;
};

export const initialServices: PartnerState = {
  company_name: '',
  product: {},
  branding_type: null,
  note: '',
  level: {
    qty: 0,
    cost: 0,
    final_cost: 0,
  },
  level0: {
    qty: 0,
    cost: 0,
    final_cost: 0,
  },
  level1: {
    qty: 0,
    cost: 0,
    final_cost: 0,
  },
  level2: {
    qty: 0,
    cost: 0,
    final_cost: 0,
  },
  level3: {
    qty: 0,
    cost: 0,
    final_cost: 0,
  },
  full_station: {
    qty: 0,
    cost: 0,
    final_cost: 0,
  },
  odo_station: {
    qty: 0,
    cost: 0,
    final_cost: 0,
  },
  mirror_station: {
    qty: 0,
    cost: 0,
    final_cost: 0,
  },
  discount: {
    percentage: 0,
    cost: 0,
  },
  laptop: {
    qty: 0,
    cost: 0,
    final_cost: 0,
  },
  setup: {
    qty: 0,
    cost: 0,
    final_cost: 0,
  },
  company_facilities: [],
  company_schools: [],
};

type IPayload = {
  service?: IService;
  school?: ISchool;
  product?: IProduct;
  index?: number;
  discount?: number;
  value?: string;
  values?: string[];
} | null;

const PartnerReducer = (
  state: PartnerState,
  action: PartnerAction,
): PartnerState => {
  const {type, payload} = action;
  switch (type) {
    case 'reset':
      return initialServices;
    case 'update_company_name':
      return {
        ...state,
        company_name: payload?.value || '',
      };
    case 'add_primary_product':
      return {
        ...state,
        product: {
          ...(payload?.product as IProduct),
        },
      };
    case 'update_product':
      return {
        ...state,
        product: {
          ...state.product,
          ...(payload?.product as IProduct),
        },
      };
    case 'district_branding':
      return {
        ...state,
        branding_type: 1,
      };

    case 'school_branding':
      return {
        ...state,
        branding_type: 2,
      };
    case 'notes':
      return {
        ...state,
        note: payload?.value ?? state.note,
      };
    case 'edit_level_price':
      return {
        ...state,
        level: {
          ...state.level,
          final_cost: Number(payload?.value) ?? state.level.cost,
        },
      };
    case 'Level0_init':
      return {
        ...state,
        level0: payload?.service ?? state.level0,
      };
    case 'Level0-':
      return {
        ...state,
        level0: payload?.service ?? state.level0,
      };
    case 'Level0+':
      return {
        ...state,
        level0: payload?.service ?? state.level0,
        level: payload?.service ?? state.level0,
      };
    case 'Level1_init':
      return {
        ...state,
        level1: payload?.service ?? state.level1,
      };
    case 'Level1-':
      return {
        ...state,
        level1: payload?.service ?? state.level1,
      };
    case 'Level1+':
      return {
        ...state,
        level1: payload?.service ?? state.level1,
        level: payload?.service ?? state.level1,
      };
    case 'Level2_init':
      return {
        ...state,
        level2: payload?.service ?? state.level2,
      };
    case 'Level2-':
      return {
        ...state,
        level2: payload?.service ?? state.level2,
      };
    case 'Level2+':
      return {
        ...state,
        level2: payload?.service ?? state.level2,
        level: payload?.service ?? state.level2,
      };
    case 'Level3_init':
      return {
        ...state,
        level3: payload?.service ?? state.level3,
      };
    case 'Level3-':
      return {
        ...state,
        level3: payload?.service ?? state.level3,
      };
    case 'Level3+':
      return {
        ...state,
        level3: payload?.service ?? state.level3,
        level: payload?.service ?? state.level3,
      };
    case 'full_station_init':
      return {
        ...state,
        full_station: payload?.service ?? state.full_station,
      };
    case 'full_station+':
      return {
        ...state,
        full_station: payload?.service ?? state.full_station,
      };
    case 'full_station-':
      return {
        ...state,
        full_station: payload?.service ?? state.full_station,
      };
    case 'odo_station_init':
      return {
        ...state,
        odo_station: payload?.service ?? state.odo_station,
      };
    case 'odo_station+':
      return {
        ...state,
        odo_station: payload?.service ?? state.odo_station,
      };
    case 'odo_station-':
      return {
        ...state,
        odo_station: payload?.service ?? state.odo_station,
      };
    case 'mirror_station_init':
      return {
        ...state,
        mirror_station: payload?.service ?? state.mirror_station,
      };
    case 'mirror_station+':
      return {
        ...state,
        mirror_station: payload?.service ?? state.mirror_station,
      };
    case 'mirror_station-':
      return {
        ...state,
        mirror_station: payload?.service ?? state.mirror_station,
      };
    case 'discount':
      let percentage = state.discount.percentage * 1 || 0;
      if (payload !== null) {
        percentage = Number(payload?.discount);
      }
      if (isNaN(percentage)) {
        percentage = 0;
      }
      const level = state.level;
      const full = state.full_station;
      const odo = state.odo_station;
      const mirror = state.mirror_station;
      const net_price =
        level.final_cost + full.final_cost + odo.final_cost + mirror.final_cost;
      const discount_price = (net_price * percentage) / 100;

      return {
        ...state,
        discount: {cost: discount_price, percentage},
      };
    case 'laptop_init':
      return {
        ...state,
        laptop: payload?.service ?? state.laptop,
      };
    case 'laptop+':
      return {
        ...state,
        laptop: payload?.service ?? state.laptop,
      };
    case 'laptop-':
      return {
        ...state,
        laptop: payload?.service ?? state.laptop,
      };
    case 'setup_init':
      return {
        ...state,
        setup: payload?.service ?? state.setup,
      };
    case 'setup+':
      return {
        ...state,
        setup: payload?.service ?? state.setup,
      };
    case 'setup-':
      return {
        ...state,
        setup: payload?.service ?? state.setup,
      };
    case 'facility+':
      return {
        ...state,
        company_facilities: payload?.values ?? state.company_facilities,
      };
    case 'facility-':
      const facility_del = state.company_facilities.filter(
        val => val !== payload?.value,
      );
      const new_schools = state.company_schools.map(facility => {
        const facility_vals = facility.facilities.filter(
          val => val !== payload?.value,
        );
        return {...facility, facilities: facility_vals};
      });
      return {
        ...state,
        company_facilities: facility_del,
        company_schools: new_schools,
      };
    case 'school+':
      if (payload?.school) {
        return {
          ...state,
          company_schools: [payload?.school, ...state.company_schools],
        };
      }
      return state;
    case 'school-':
      if (payload?.index !== undefined && payload?.index !== null) {
        const newSchools = state.company_schools.filter(
          (_, index) => index !== payload?.index,
        );
        return {
          ...state,
          company_schools: [...newSchools],
        };
      }
      return state;

    default:
      return state;
  }
};

export type PartnerParms = {
  dispatch: React.Dispatch<PartnerAction>;
  state: PartnerState;
};

export default PartnerReducer;
